import { Navigate, useOutlet } from "react-router-dom";
import {
    Container,
    Stack,
    Flex,
} from '@chakra-ui/react'
import { useAuth } from "../services/auth.service";
import routenames from "../common/routenames";
import { PasswordFieldRefComp } from './FormElements';
import { LoginFormValues } from '../types/user.type';
import { Navbar } from './Navbar';

export const PasswordField = PasswordFieldRefComp<LoginFormValues>;

export const HomeLayout: React.FC<{}> = ({ }) => {
    const outlet = useOutlet();
    const { isLoggedIn } = useAuth();

    if (isLoggedIn) {
        return <Navigate to={routenames.app} />;
    }

    return (
        <Flex direction="column" flex="1">
            <Navbar />
            <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
                <Stack spacing="8">
                    {outlet}
                </Stack>
            </Container>
        </Flex>
    );
}