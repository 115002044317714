import React, { FC, useMemo, useState } from "react"
import {
    Alert, AlertIcon, Button, Center, Editable, EditableInput, EditablePreview,
    Flex, FormControl, FormErrorMessage,
    Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner,
    Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr
} from "@chakra-ui/react"
import { Form, Formik, Field, FormikProps, FormikHelpers } from "formik"
import { CategoryPickerOption, CategoryPicker } from "./CategoryPicker.component"
import { SplitTransactionValues, Transaction } from "../types/server.type"
import { Input } from "./FormElements"

interface SplitTransactionModalProps {
    splits: SplitTransactionValues[]
    transaction: Transaction
    errorMessage: string | null,
    bucketList: readonly CategoryPickerOption[] | null
    onSaved: (input: { splits: SplitTransactionValues[] }) => void,
    onModalClosed: () => void,
    open: boolean
    loading?: boolean,
}
export const SplitTransactionModal: FC<SplitTransactionModalProps> =
    ({ loading, onSaved, errorMessage, onModalClosed, bucketList, open, transaction, splits }) => {
        const nameInputRef = React.useRef<HTMLInputElement>(null);

        const [numSplits, setNumSplits] = useState<number>(splits.length);
        const amount = useMemo(() => splits.reduce((accumulator: number, current) => accumulator + Math.abs(current.amount), 0), []);

        const initialValues = { splits };

        const handleSaved = (values: typeof initialValues, form: FormikHelpers<typeof initialValues>) => {
            let hasZeroAmount = false;
            const sum = values.splits.reduce((accumulator: number, current) => {
                if (current.amount === 0) {
                    hasZeroAmount = true;
                }
                return accumulator + (current.amount * 100);
            }, 0);

            if (hasZeroAmount) {
                form.setSubmitting(false);
                form.setStatus(`A transaction's amount cannot be 0`);
                return;
            }

            if (sum !== Math.round(amount * 100)) {
                form.setSubmitting(false);
                form.setStatus(`Total amount must add up to $${amount}`);
                return;
            }

            // todo: wait till field values have been set before sending since we use setValues
            form.setStatus();
            onSaved(values);
        };

        const handleSplitAgainClicked = (form: FormikProps<typeof initialValues>) => setNumSplits((curr) => {
            const newValues = structuredClone(form.values);
            newValues.splits.push({
                amount: 0,
            });

            form.setValues(newValues);
            return curr + 1;
        });

        const handleSplitRemoveClicked = (form: FormikProps<typeof initialValues>) => setNumSplits((curr) => {
            if (curr === 1) {
                return curr;
            }

            const newValues = structuredClone(form.values);
            newValues.splits.pop();
            form.setValues(newValues);
            return curr - 1;
        });

        return (
            <Modal
                isCentered
                onClose={onModalClosed}
                isOpen={open}
                motionPreset='slideInBottom'
                size={'4xl'}
                initialFocusRef={nameInputRef}>
                <ModalOverlay />
                <Formik initialValues={initialValues} onSubmit={handleSaved}>
                    {(form) => (
                        <Form>
                            <ModalContent>
                                <ModalHeader>Split Transaction</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody pb={6}>
                                    <TableContainer overflowY='scroll' height='450px'>
                                        <Table variant='simple'>
                                            <TableCaption placement="top">Original Amount: ${amount}</TableCaption>
                                            <Thead>
                                                <Tr>
                                                    <Th>Description</Th>
                                                    <Th>Amount</Th>
                                                    <Th>Bucket</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {Array.from(new Array(numSplits), (_, id) => (
                                                    <Tr key={id}>
                                                        <Td>
                                                            <Field name={`splits[${id}].description`}>
                                                                {({ field, form }: any) => <Input form={form} field={field} value={field.value ?? ''} removeLabel placeholder={transaction.description}
                                                                    onClick={() => form.setFieldValue(field.name, field.value || transaction.description)} />}
                                                            </Field>
                                                        </Td>
                                                        <Td><Flex><Text paddingTop='4px'>$</Text>
                                                            <Field name={`splits[${id}].amount`}>
                                                                {({ field, form }: any) =>
                                                                    <FormControl isInvalid={form.errors.amount}><Editable defaultValue={field.value || `0.00`}>
                                                                        <EditablePreview />
                                                                        <EditableInput {...field} type='number' />
                                                                    </Editable>
                                                                        <FormErrorMessage>{form.errors.amount}</FormErrorMessage>
                                                                    </FormControl>
                                                                }
                                                            </Field>
                                                        </Flex>
                                                        </Td>
                                                        <Td>
                                                            <Field name={`splits[${id}].bucketid`}>
                                                                {({ field, form }: any) => (
                                                                    <FormControl isInvalid={form.errors.bucketid}>
                                                                        <CategoryPicker options={bucketList} {...field} hasError={!!(form.errors.bucketid)}
                                                                            nonSelectedText="No parent"
                                                                            value={field.value || transaction.bucketid}
                                                                            onSelectedBucketChange={(newBucket: CategoryPickerOption) => form.setFieldValue(field.name, newBucket.value)} />
                                                                        <FormErrorMessage>{form.errors.bucketid}</FormErrorMessage>
                                                                    </FormControl>
                                                                )}
                                                            </Field>
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                    <Center mt='25px'>
                                        <Button colorScheme='blue' onClick={() => handleSplitAgainClicked(form)}>Split again</Button>
                                        <Button ml='25px' onClick={() => handleSplitRemoveClicked(form)}>Remove last split</Button>
                                    </Center>
                                    {(errorMessage || form.status) && <Alert mt='20px' status='error'> <AlertIcon />{errorMessage || form.status}</Alert>}

                                </ModalBody>
                                <ModalFooter>
                                    {loading ? <Spinner marginRight={'40px'} /> : <Button type="submit" colorScheme='teal' variant='solid' mr={4} >Complete Split</Button>}
                                    <Button onClick={onModalClosed}>Cancel</Button>
                                </ModalFooter>
                            </ModalContent >
                        </Form>)}
                </Formik>
            </Modal >
        )
    }
