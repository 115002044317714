import { TransactionFilterParams } from "../types/user.type";
import Constants from "./constants";

const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
const isDebug = process.env.REACT_APP_RELEASE_ENV === 'development';
export const REPAIR_MODE = process.env.REACT_APP_REPAIR_MODE === 'true';

function isObject(object: any) {
    return object != null && typeof object === 'object';
}
const transactionFilterKeys: readonly ['buckets', 'credit', 'dateRange', 'debit', 'description'] = ['buckets', 'credit', 'dateRange', 'debit', 'description'];

export function ToUrlQuery(parameters: Partial<TransactionFilterParams>) {
    const searchParams = new URLSearchParams();
    for (const key of transactionFilterKeys) {
        if (parameters[key]) {
            searchParams.append(key, JSON.stringify(parameters[key]));
        }
    }
    return searchParams.toString();
}

export function FromUrlQuery(query: string): Partial<TransactionFilterParams> {
    const searchParams = new URLSearchParams(query);
    const filterParams: Partial<TransactionFilterParams> = {};

    for (const key of transactionFilterKeys) {
        const searchValue = searchParams.get(key);
        try {
            if (!searchValue) continue;
            filterParams[key] = JSON.parse(searchValue);
        } catch (error) {
            logger.error(`Error while gettng filter params from query. Key-Value = (${key}, ${searchValue})`);
            logger.error(error);
        }
    }
    return filterParams;
}

export default {
    GetFriendlyDate(date: string, locales?: Intl.LocalesArgument) {
        return (new Date(date)).toLocaleDateString(locales, options)
    },

    GetFriendlyBucketName(bucketname: string) {
        return bucketname.charAt(0).toUpperCase() + bucketname.slice(1)
    },

    GetFriendlyAmount(num: number) {
        return (Math.round(num * 100) / 100).toFixed(2);
    },

    GetSuggestedBudgetAmount(amount: number) {
        return amount > 0 ? Math.ceil(amount) : 50;
    },

    GetAmountAsCurrency(amount: number, currency = 'USD'): string {
        return amount.toLocaleString(navigator.language || 'en-US', {
            style: 'currency',
            currency,
        });
    },

    SortByObjectName(a: { name: string; }, b: { name: string; }) {
        let x = a.name.toLowerCase();
        let y = b.name.toLowerCase();
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
    },

    SplitList<T>(list: T[], filterFn: (elem: T) => boolean, passed: T[] = [], failed: T[] = []) {
        for (var i = 0; i < list.length; i++)
            if (filterFn(list[i]))
                passed.push(list[i]);
            else
                failed.push(list[i]);
        return [passed, failed];
    },
    DeepEqual(object1: any, object2: any) {
        if (object1 === object2) return true;
        if (object1 === null && object2 === null) return true;
        if (!object1 && object2 || !object2 && object1 || (!object1 && !object2)) {
            return false;
        }

        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = isObject(val1) && isObject(val2);
            if (

                areObjects && !this.DeepEqual(val1, val2) ||
                !areObjects && val1 !== val2
            ) {
                return false;
            }
        }

        return true;
    },
    IsUserDefinedBucket(id: number) {
        return id > Constants.UserDefinedBase;
    },
    CapitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    EmptyFunction() { },


};

export const logger = {
    log: (...data: any[]) => isDebug && console.log(...data),
    warn: (...data: any[]) => isDebug && console.warn(...data),
    error: (...data: any[]) => console.error(...data),
};