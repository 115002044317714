import axios, { AxiosError, AxiosResponse } from "axios"
import { ClientRequest, EmptyResponse } from "../types/server.type";
import { logger } from "./utils";
import authService from "../services/auth.service";

const { REACT_APP_SERVER_ADDRESS, REACT_APP_API_GATEWAY, REACT_APP_CLIENT_ADDRESS } = process.env;

function validateEnvironmentParams() {
    if (REACT_APP_API_GATEWAY &&
        REACT_APP_API_GATEWAY.charAt(REACT_APP_API_GATEWAY.length - 1) != '/') {
        throw new Error("Malformed REACT_APP_API_GATEWAY. Must end with '/'");
    }

    if (!REACT_APP_SERVER_ADDRESS) {
        throw new Error("REACT_APP_SERVER_ADDRESS must be defined");
    }

    if (!REACT_APP_CLIENT_ADDRESS) {
        throw new Error("REACT_APP_CLIENT_ADDRESS must be defined");
    }
};
validateEnvironmentParams();

const apiGateway = REACT_APP_API_GATEWAY ?? '';
export const API_GATEWAY = process.env.REACT_APP_API_GATEWAY;
export const API_URL = `${REACT_APP_SERVER_ADDRESS}/${apiGateway}`; // checked in validateEnvironmentParams
export const APP_URL = process.env.REACT_APP_CLIENT_ADDRESS as string; // checked in validateEnvironmentParams

export interface Result<T = undefined> {
    success: boolean
    message: string
    data?: T
    logout?: boolean
}

export function BuildRequest<T>(data: T): ClientRequest<T> {
    return {
        meta: {
            clientName: 'web-app'
        },
        data,
    }
}

// TODO: may no longer need withCred when using the same domain
export const api = {
    async post<D, Res = EmptyResponse>(url: string, data: D): Promise<Res> {
        return (await axios.post<Res, AxiosResponse<Res>, ClientRequest<D>>(API_URL + url, BuildRequest(data), { withCredentials: true })).data;
    },
    async get<Res>(url: string): Promise<Res> {
        return (await axios.get<Res, AxiosResponse<Res>>(API_URL + url, { withCredentials: true })).data;
    },
}

export function handleFailedApiCall<T extends { errorMessage: string } = EmptyResponse>(error: unknown, defaultErrorMessage: string): Result {
    logger.log(error);
    let result: Result = { success: false, message: defaultErrorMessage };

    if (error instanceof AxiosError) {
        const err = error as AxiosError<T>;
        const status = err.response?.status;
        if (status === axios.HttpStatusCode.Unauthorized) {
            authService.logout();
            result.message = 'Not logged in. Please login and try again';
            result.logout = true;
        } else if ((status !== undefined) && (status < axios.HttpStatusCode.InternalServerError)) {
            result.message = err.response?.data.errorMessage ?? defaultErrorMessage
        }
    } else {
        // it was some other kind of error, handle it appropriately
        logger.log('Error was completely unexpected');
    }

    return result;
}