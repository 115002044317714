import React, { FC } from "react";
import { Flex, Spinner } from '@chakra-ui/react';
import Select, { ActionMeta, CSSObjectWithLabel, LoadingIndicatorProps, MultiValue, OptionProps, SingleValue, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import utils, { logger } from "../common/utils";

export type CategoryPickerOption = { label: string; value: number; isParent?: boolean; isDisabled?: boolean; };
export interface CategoryPickerProps<Ctx = any> {
    context?: Ctx
    placeholder?: string
    options: readonly CategoryPickerOption[] | null;
    value?: number | null;
    nonSelectedText?: string,
    hasError?: boolean;
    disableDefaultSelected?: boolean
    onSelectedBucketChange: (newBucket: CategoryPickerOption, context?: Ctx) => void;
    handleCreate?: (input: string, context?: Ctx) => void
}

export interface MultiCategoryPickerProps extends Omit<CategoryPickerProps, 'onSelectedBucketChange' | 'value'> {
    onChange: (selectedBucketIds: readonly number[]) => void;
    value: readonly number[];
}

// TODO(UX) style the option. Especially padding
const CategoryOption = (props: OptionProps<CategoryPickerOption, false>) => {
    const boldProp = props.data.isParent ? 'b' : undefined;
    return (
        // <Text as={boldProp} > // TODO: cannot use text here because it renders as <p> which cannot be a parent of <div>
        <components.Option {...props} />
        // </Text>
    );
};

const CategoryLoadingIndicator = (props: LoadingIndicatorProps<any, false>) => {
    return (
        <Flex marginRight={'5%'}>
            <Spinner size={'sm'} color="gray.300" />
        </Flex>
    );
};

const categoryPicker: FC<CategoryPickerProps> = ({ context, options, value, hasError = false, placeholder, nonSelectedText = 'Uncategorized', disableDefaultSelected, handleCreate, onSelectedBucketChange }) => {
    if (!options) {
        return <Select isLoading={true} placeholder={'Loading'}
            loadingMessage={(_) => <Spinner size={'sm'} color="gray.300" />}
            components={{ Option: CategoryOption, LoadingIndicator: CategoryLoadingIndicator }} />;
    }

    const nonSelectedBucket: CategoryPickerOption = { label: nonSelectedText, isDisabled: true, value: 0 };
    const bucket = (value) ? options.find((option) => option.value === value) : nonSelectedBucket;

    const handleSelect = (newSelectedBucket: SingleValue<CategoryPickerOption>, _: ActionMeta<CategoryPickerOption>) => {
        if (newSelectedBucket === null) {
            logger.error('Failure on bucket selection');
            return;
        }
        onSelectedBucketChange(newSelectedBucket, context);
    };

    const getOption = (option: CategoryPickerOption) => option.value === nonSelectedBucket.value ? nonSelectedBucket : option;

    const selectProps = {
        styles: {
            container: (baseStyles: CSSObjectWithLabel) => ({ ...baseStyles, minWidth: '80%' }),
            control: (baseStyles: CSSObjectWithLabel, state: any) => ({
                ...baseStyles,
                borderColor: (!state.isFocused && hasError) ? 'crimson' : baseStyles.borderColor,
                boxShadow: (state.isFocused || hasError) ? `0 0 0 1px ${(state.isFocused) ? baseStyles.borderColor : 'crimson'}` : baseStyles.boxShadow
            }),
        },
        name: 'categories',
        options,
        placeholder,
        components: { Option: CategoryOption, LoadingIndicator: CategoryLoadingIndicator },
        value: bucket,
        isOptionDisabled: disableDefaultSelected ? ((option: CategoryPickerOption) => option.value === nonSelectedBucket.value) : undefined,
        getOptionLabel: (option: CategoryPickerOption) => getOption(option).label,
        getOptionValue: (option: CategoryPickerOption) => `${getOption(option).value}`,
        onChange: handleSelect,
    };

    if (handleCreate) {
        return <CreatableSelect {...selectProps} onCreateOption={(input) => handleCreate(input, context)} isMulti={false} />;
    }

    return <Select {...selectProps} isMulti={false} />
};

const multiCategoryPicker: FC<MultiCategoryPickerProps> = ({ options, value, hasError = false, nonSelectedText = 'Uncategorized', onChange: onSelectedBucketsChange, placeholder }) => {
    const buckets = options ? options.filter((option) => { return value.includes(option.value) }) : [];
    const nonSelectedBucket: CategoryPickerOption = { label: nonSelectedText, isDisabled: true, value: 0 };

    const handleSelect = (selectedBuckets: MultiValue<CategoryPickerOption>) => {
        if (selectedBuckets === null) {
            logger.error('Failure on bucket selection');
            return;
        }
        onSelectedBucketsChange(selectedBuckets.map((option) => option.value));
    };

    return (options) ?
        <Select
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: (!state.isFocused && hasError) ? 'crimson' : baseStyles.borderColor,
                    boxShadow: (state.isFocused || hasError) ? `0 0 0 1px ${(state.isFocused) ? baseStyles.borderColor : 'crimson'}` : baseStyles.boxShadow
                }),
            }}
            name="categories"
            isMulti={true}
            options={options}
            // components={{ Option: CategoryOption, LoadingIndicator: CategoryLoadingIndicator }}
            value={buckets ?? [nonSelectedBucket]}
            getOptionLabel={option => option.label}
            getOptionValue={option => `${option.value}`}
            onChange={handleSelect}
            placeholder={placeholder}
        />
        :
        <Select isLoading={true} placeholder={'Loading'}
            loadingMessage={(_) => <Spinner size={'sm'} color="gray.300" />}
            components={{ Option: CategoryOption, LoadingIndicator: CategoryLoadingIndicator }} />;
};

export const CategoryPicker = React.memo(categoryPicker, (prevProps, newProps) => {
    return prevProps.value === newProps.value && utils.DeepEqual(prevProps.options, newProps.options);
});

export const MultiCategoryPicker = React.memo(multiCategoryPicker);
