import React, { FC, RefObject } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { AbsoluteCenter, Box, Editable, EditableInput, EditablePreview, Flex, Icon, Link as ChakraLink, Progress, Spinner, Stack, Text } from '@chakra-ui/react';
import { ChevronDown, ChevronRight } from 'react-bootstrap-icons';

import utils, { ToUrlQuery } from "../common/utils";
import { EditBucketModal, EditBucketValues } from "./CreateBucketModal.component";
import { Bucket } from "../types/server.type";
import { CategoryPickerOption } from "./CategoryPicker.component";
import routenames from "../common/routenames";

export type SpendProgressProps = {
    bucket: Bucket
    // not tracked on prop change because it alone should not trigger re-render
    parentId?: number
    refToThis?: RefObject<HTMLDivElement>

    parentName?: string;
    closed?: boolean;
    showCursor: boolean;
    loader?: boolean;
    editLoading?: boolean;
    goalEditError?: string;
    bucketEditError: string | null;
    bucketList: CategoryPickerOption[] | null
    onCursorClicked: (id: number) => void;
    onAmountChanged: (id: number, newAmount: number) => void;
    onEditSaved: (id: number, values: EditBucketValues) => void;
    onEditClosed: () => void;
};

const arePropsEqual = (prevProps: SpendProgressProps, newProps: SpendProgressProps) => {
    return prevProps.bucket.id === newProps.bucket.id &&
        prevProps.closed === newProps.closed &&
        prevProps.bucket.name === newProps.bucket.name &&
        prevProps.parentName === newProps.parentName &&
        prevProps.bucket.goal === newProps.bucket.goal &&
        prevProps.bucket.expense === newProps.bucket.expense &&
        prevProps.showCursor === newProps.showCursor &&
        prevProps.editLoading === newProps.editLoading &&
        prevProps.goalEditError === newProps.goalEditError &&
        prevProps.bucketEditError === newProps.bucketEditError &&
        prevProps.bucketList === newProps.bucketList && // TODO(PERF) is this causing unnecessary re-renders
        prevProps.loader === newProps.loader;
};

const expenseColorScheme = ['green', 'yellow', 'orange', 'red'];
const incomeColorScheme: readonly string[] = [...expenseColorScheme].reverse();

const spendProgress: FC<SpendProgressProps> =
    ({ bucket, goalEditError, parentName, showCursor, closed, refToThis: finalRef, bucketEditError = null,
        onCursorClicked, onAmountChanged, onEditSaved, onEditClosed,
        loader, bucketList, parentId, editLoading }) => {
        const { id, name, goal, isIncome } = bucket;
        const expense = isIncome ? Math.abs(bucket.expense) : bucket.expense;
        let progressValue = 100;
        if (goal !== 0) {
            progressValue *= expense / goal;
        }
        if (expense < 0) {
            progressValue = 0;
        }

        const scheme = isIncome ? incomeColorScheme : expenseColorScheme;
        let colorScheme = scheme.length - 1;
        if (progressValue < 50) {
            colorScheme = 0;
        } else if (progressValue < 75) {
            colorScheme = 1;
        } else if (progressValue < 100) {
            colorScheme = 2;
        }

        const goalDisplayValue = `${utils.GetFriendlyAmount(goal)}`;

        const handleSubmit = (val: string) => {
            onAmountChanged(id, Number(val));
        };

        const handleEditSaved = (values: EditBucketValues) => {
            onEditSaved(id, values);
        };

        const bgGradient = parentName ? [
            // child bgGraident
            'linear(to-br, green.300, teal.100)',
            'linear(to-tr, green.200, teal.100)',
        ] : [
            // parent bgGraident
            'linear(to-br, green.400, teal.200)',
            'linear(to-tr, green.400, teal.200)',
        ];

        return (
            <Flex ref={finalRef} justifyContent='flex-start' boxShadow='2xl' rounded='md' width='100%' bgGradient={bgGradient}>
                {showCursor &&
                    <Stack width='9%' onClick={(e) => { e.preventDefault(); onCursorClicked(id); }}
                        position='relative' borderRight={'thin'} borderRightStyle={'groove'} _hover={{ opacity: '30%' }}>
                        <AbsoluteCenter color='white' axis='both'>
                            {closed ? <ChevronDown size={30} /> : <ChevronRight size={30} />}
                        </AbsoluteCenter>
                    </Stack>}
                <Stack padding='2%' rounded='md' width='100%'>
                    <Box as='b' fontFamily={'sans-serif'} color={'black.200'} fontSize={'lg'}>
                        <ChakraLink as={ReactRouterLink} _hover={{ opacity: '30%' }} style={{ textDecoration: 'none' }}
                            to={`${routenames.transactions}/${ToUrlQuery({ buckets: [bucket.id] })}`}
                        >{name}</ChakraLink>{' '}
                        {utils.IsUserDefinedBucket(bucket.id) && <EditBucketModal initialValues={{ ...bucket, parentId, isIncome: bucket.isIncome ? true : undefined }}
                            errorMessage={bucketEditError} bucketList={bucketList}
                            onSaved={handleEditSaved}
                            submitLoading={editLoading}
                            onModalClosed={onEditClosed} />}
                    </Box>
                    <Flex alignSelf='flex-end'>
                        <Box alignContent={'center'}>{`Spent $${utils.GetFriendlyAmount(expense)} of $`}</Box>
                        {loader ? <Spinner /> :
                            // key is used here to force a re-render because only the defaultValue changes and that
                            // prop seems to not be used as a determinant for re-rerender. An alternative would've been
                            // to use value (instead of defaultValue) and create state to manage value but I think this is better
                            <Editable key={Math.random()} alignSelf='flex-end' fontFamily={'sans-serif'} fontSize='md'
                                onSubmit={handleSubmit} defaultValue={goalDisplayValue}>
                                <EditablePreview color={goalEditError ? 'crimson' : undefined} />
                                <EditableInput />
                            </Editable>}
                    </Flex>
                    <Progress value={progressValue} colorScheme={scheme[colorScheme]}
                        size='lg' rounded='md' boxShadow='2xl' bg='gray.200' />
                </Stack>
            </Flex>
        );
    };

export const SpendProgress = React.memo(spendProgress, arePropsEqual);
