import { useAuth } from "../services/auth.service";
import { Navigate, useLocation, useOutlet } from "react-router-dom";
import routenames from "../common/routenames";

export const RequireAuth = () => {
    const { isLoggedIn } = useAuth();
    const outlet = useOutlet();
    const location = useLocation();

    if (!isLoggedIn) {
        return <Navigate to={routenames.login} state={{ redirectTo: location.pathname }} replace />;
    }
    return outlet;
};