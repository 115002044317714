import React, { FC, RefObject } from "react";
import {
    AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader,
    AlertDialogOverlay, Button, Text
} from '@chakra-ui/react';

interface GoalEditConfirmationModalProps {
    name: string;
    isParent: boolean;
    parentName?: string;
    updatedGoal: number;
    goalSelected: number;
    shouldBeOpen: boolean;
    noChange: boolean;
    finalFocusRef: RefObject<any>
    cancel: () => void;
    confirm: () => void;
}

export const GoalEditConfirmationModal: FC<GoalEditConfirmationModalProps> = (props) => {
    const { name, parentName, updatedGoal, shouldBeOpen, isParent, goalSelected, noChange, finalFocusRef, cancel, confirm } = props;
    const cancelAlertRef = React.useRef(null);
    return (
        <AlertDialog
            isOpen={shouldBeOpen}
            leastDestructiveRef={cancelAlertRef}
            finalFocusRef={finalFocusRef}
            onClose={cancel}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Confirm <b>{name}</b> goal edit
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {isParent ?
                            (noChange ?
                                <>
                                    <Text>Cannot set <b>{name}</b>'s goal to ${goalSelected} because its goal cannot be less than the sum of the children bucket's goal.</Text>
                                    <Text><b>{name}</b>'s goal will remain at ${updatedGoal}. Would you like to continue?</Text>
                                </> :
                                <>
                                    <Text>Cannot set <b>{name}</b>'s goal to ${goalSelected} because its goal cannot be less than the sum of the children bucket's goal.</Text>
                                    <Text><b>{name}</b>'s goal will be set to ${updatedGoal}. Would you like to set it to this value?</Text>
                                </>) :
                            <>
                                <Text>Cannot set <b>{name}</b>'s goal to ${goalSelected} because that will make its parent's goal less than the sum of the children bucket's goal.</Text>
                                <Text>The parent, <b>{parentName}</b>'s goal will be set to ${updatedGoal}. Would you like to continue with this value?</Text>
                            </>}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelAlertRef} onClick={cancel}>
                            No
                        </Button>
                        <Button colorScheme='orange' onClick={noChange ? cancel : confirm} ml={3}>
                            Yes
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
