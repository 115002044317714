import React, { useState, FC } from "react";

import {
  AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader,
  AlertDialogOverlay, Button, Collapse, List, ListIcon, ListItem, Text, useDisclosure
} from '@chakra-ui/react';
import { PlusCircleFill } from 'react-bootstrap-icons';

import cache, { data, useDataGetter } from "../services/cache.service";
import { Bucket, ParentBucket } from "../types/server.type";
import userService, { CreateBucketParams } from "../services/user.service";
import utils, { logger } from "../common/utils";
import { SpendProgress } from "../components/SpendProgress.component";
import { CreateBucketModal, EditBucketValues } from "../components/CreateBucketModal.component";
import { CategoryPickerOption } from "../components/CategoryPicker.component";
import { Result } from "../common/api";
import { GoalEditConfirmationModal } from "../components/GoalEditConfirmationModal";
import { ModalLink } from "../components/ModalLink";

type BucketExtraUI = {
  closed?: boolean
  waitingForData?: boolean
  errorMessage?: string
  hasTrackedChildren?: boolean
  parent?: number
  parentName?: string
  hide?: boolean
  isParent?: boolean
};
type BucketUI = Bucket & BucketExtraUI;
type ParentBucketUI = ParentBucket & BucketExtraUI;
type BucketsState = {
  tracked: BucketUI[],
  untracked: BucketUI[],
  trackedIncome: BucketUI[],
  untrackedIncome: BucketUI[],
};

interface BucketList {
  expense: CategoryPickerOption[]
  income: CategoryPickerOption[]
}

function handleNewParentBucketChildren(bucket: ParentBucketUI, tracked: BucketUI[] = [], untracked: BucketUI[]) {
  if (!bucket.children || bucket.children.length === 0) return;
  bucket.isParent = true;
  let totalChildExpenses = 0;
  bucket.expense *= 100;
  bucket.children.sort(utils.SortByObjectName);
  for (const child of bucket.children) {
    const childUI = child as BucketUI;
    childUI.waitingForData = false;

    totalChildExpenses += (childUI.expense * 100);
    if (child.goal !== 0) {
      childUI.parent = bucket.id;
      childUI.parentName = bucket.name;
      tracked.push(childUI);
      bucket.hasTrackedChildren = true;
    } else {
      childUI.parentName = bucket.name;
      untracked.push(childUI);
    }
  }

  // this *100 is done to remove floating point precisions
  // TODO: consider doing this on the server since the server already doesn't use floats
  bucket.expense -= totalChildExpenses;
  bucket.expense /= 100;
}

function AreBucketsTheSame(oldBuckets: BucketsState | null, newBuckets: BucketsState | null) {
  return (utils.DeepEqual(oldBuckets?.tracked, newBuckets?.tracked) &&
    utils.DeepEqual(oldBuckets?.untracked, newBuckets?.untracked) &&
    utils.DeepEqual(oldBuckets?.trackedIncome, newBuckets?.trackedIncome) &&
    utils.DeepEqual(oldBuckets?.untrackedIncome, newBuckets?.untrackedIncome));
}

// Is responsible for initializing the UI structs based on the structs received from the server and 
// sorting them in the right order
function CreateBucketList(list: ParentBucketUI[]) {
  let tracked: BucketUI[] = [], untracked: BucketUI[] = [];
  let trackedIncome: BucketUI[] = [], untrackedIncome: BucketUI[] = [];

  // First sort the parent alphabetically. This is done for displaying parent buckets
  // alphabetically in the tracked list
  list.sort(utils.SortByObjectName);
  for (let i = 0; i < list.length; i++) {
    list[i].waitingForData = false;
    const trackedPool = list[i].isIncome ? trackedIncome : tracked;
    const untrackedPool = list[i].isIncome ? untrackedIncome : untracked;
    if (list[i].goal !== 0) {
      trackedPool.push(list[i]);
      handleNewParentBucketChildren(list[i], trackedPool, untrackedPool);
    } else {
      untrackedPool.push(list[i]);
      handleNewParentBucketChildren(list[i], trackedPool, untrackedPool);
    }
  }

  return [tracked, untracked, trackedIncome, untrackedIncome];
};

function getBucketUpdates(buckets: ParentBucketUI[]) {
  const [tracked, untrackedUnfiltred, trackedIncome, untrackedIncomeUnfiltred] = CreateBucketList((buckets || []));

  // only show untracked buckets that an expense has been made on
  const untracked = untrackedUnfiltred.filter((bucket) => bucket.expense !== 0);
  const untrackedIncome = untrackedIncomeUnfiltred.filter((bucket) => bucket.expense !== 0);
  return [tracked, untracked, trackedIncome, untrackedIncome];
}

function getBucketChildrenGoalTotal(bucket: ParentBucketUI): number {
  let total = 0;
  for (const child of bucket.children) {
    total += child.goal
  }

  return total
}

// This validation is based on a server validation so updates to that should be reflected here too
function checkIfNewGoalUpdatesParentGoalOrSelf(bucket: BucketUI, newGoal: number, buckets: BucketUI[]): { goal: number, isParent: boolean } | null {
  if (bucket.parent) {
    // if the goal is being decreased, the parent's goal is unaffected so we can don't need more updates
    if (newGoal < bucket.goal) {
      return null;
    }

    const parent = buckets.find((b) => b.id === bucket.parent) as ParentBucketUI;
    if (!parent) {
      logger.error(`Parent of ${bucket.id} with id: ${bucket.parent} was not found`);
      return null
    }

    let totalChildrenGoal = getBucketChildrenGoalTotal(parent);
    totalChildrenGoal += (newGoal - bucket.goal); // this accounts for the new goal
    if (parent.goal < totalChildrenGoal) {
      return { goal: totalChildrenGoal, isParent: false };
    }

    return null;
  }

  if (newGoal > bucket.goal) {
    // if the goal is being increased, the children's total won't matter
    return null
  }

  const bucketAsParent = bucket as ParentBucketUI;
  if (!bucketAsParent.children || bucketAsParent.children.length === 0) {
    return null
  }
  let totalChildrenGoal = getBucketChildrenGoalTotal(bucketAsParent);

  if (newGoal < totalChildrenGoal) {
    return { goal: totalChildrenGoal, isParent: true };
  }

  return null;
}

const Budget: FC<{}> = () => {

  const [buckets, setBuckets] = useState<BucketsState | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [modalError, setModalError] = useState<string | null>(null);
  const [modalLoader, setModalLoader] = useState<boolean>(false);
  const [createBucketLoader, setCreateBucketLoader] = useState<boolean>(false);
  const [createBucketError, setCreateBucketError] = useState<string | null>(null);
  const [editBucketError, setEditBucketError] = useState<string | null>(null);
  const [editBucketLoader, setEditBucketLoader] = useState<boolean>(false);
  const [editBucketId, setEditBucketId] = useState<number | null>(null);
  const [parentBucketList, setParentBucketList] = useState<BucketList | null>(null);
  const [deleteBucketAlert, setDeleteBucketAlert] = useState<Bucket | null>(null);
  const [confirmGoalEdit, setConfirmGoalEdit] = useState<BucketUI & { goalSelected: number, updatedGoal: number, isParent: boolean, acceptFunc: (run: boolean) => void, noChange: boolean } | null>(null);

  const createBucketInitialValues = {
    name: '',
    goal: 50,
    parentId: 0,
  };

  const createExpenseBucketInitialValues: CreateBucketParams = {
    ...createBucketInitialValues,
    rollover: false,
    isIncome: undefined,
  };

  const createIncomeBucketInitialValues: CreateBucketParams = {
    ...createBucketInitialValues,
    isIncome: true,
    rollover: undefined,
  };

  const cancelAlertRef = React.useRef(null);
  const { isOpen: alertIsOpen, onOpen: alertOnOpen, onClose: alertOnClose } = useDisclosure();
  const { isOpen: goalEditConfirmIsOpen, onOpen: goalEditConfirmOnOpen, onClose: goalEditConfirmOnClose } = useDisclosure();

  const updateBuckets = (buckets: ParentBucketUI[], maintainBucketsState?: boolean) => {
    setBuckets((oldBuckets) => {
      const [tracked, untracked, trackedIncome, untrackedIncome] = getBucketUpdates(buckets);
      const newBuckets = { tracked, untracked, trackedIncome, untrackedIncome };
      if (AreBucketsTheSame(oldBuckets, newBuckets)) {
        // no new updates
        return oldBuckets;
      }

      if (maintainBucketsState && oldBuckets?.tracked) {
        // we have to maintain the hide state of the already existing tracked buckets
        for (const trackedOldbucket of oldBuckets.tracked) {
          let newTrackedBucket = tracked.find((bucket) => bucket.id === trackedOldbucket.id);
          if (newTrackedBucket)
            newTrackedBucket.closed = trackedOldbucket.closed;
        }
      }

      return newBuckets;
    });

    setParentBucketList((oldParentList) => {
      if (buckets.length === 0) return null;

      // TODO(OPTIMIZE?)
      const incomeParentList = buckets.filter((bucket) => bucket.isIncome).map((bucket) => ({ label: bucket.name, value: bucket.id }));
      const expenseParentList = buckets.filter((bucket) => !bucket.isIncome).map((bucket) => ({ label: bucket.name, value: bucket.id }));

      const incomeChanged = !utils.DeepEqual(oldParentList?.income, incomeParentList);
      const expenseChanged = !utils.DeepEqual(oldParentList?.expense, expenseParentList);

      if (!incomeChanged && !expenseChanged) {
        return oldParentList;
      }

      return {
        expense: expenseChanged ? expenseParentList : (oldParentList?.expense ?? []),
        income: incomeChanged ? incomeParentList : (oldParentList?.income ?? []),
      };
    });
  };

  const updateBucketWaitingAndErrorState = (bucketid: number, isWaiting: boolean, errorMessage?: string) => {
    setBuckets((prevBuckets) => {
      if (!prevBuckets) return prevBuckets;
      let tracked: BucketUI[] = structuredClone(prevBuckets.tracked);
      let bucket = tracked.find((b) => b.id === bucketid);
      if (!bucket) {
        return prevBuckets;
      }

      bucket.waitingForData = isWaiting;
      bucket.errorMessage = errorMessage;

      return { ...prevBuckets, tracked };
    });
  };

  const handleExpandParentBucket = (id: number) => {
    setBuckets((oldBuckets) => {
      if (!oldBuckets) {
        // this should be impossible
        return oldBuckets;
      }

      // we only need to search traked buckets because you can't expand untracked
      let tracked: BucketUI[] = structuredClone(oldBuckets.tracked);
      let bucket = tracked.find((b) => b.id === id);
      if (bucket) {
        bucket.closed = !(bucket.closed);

        // Stop displaying the child buckets if the expansion is closed
        // and show the child buckets if not closed
        tracked.forEach((current) => {
          if (!bucket) return;
          if (current.parent === bucket.id) {
            current.hide = bucket.closed;
          }
        });

      }
      return { ...oldBuckets, tracked };
    });
  };

  const handleGetGoalEditConfirmation = (editParameters: NonNullable<typeof confirmGoalEdit>) => {
    goalEditConfirmOnOpen();
    setConfirmGoalEdit(editParameters);
  };

  const handleGoalEditConfirmationCancel = () => {
    if (!confirmGoalEdit) {
      logger.error(`Cancel called on goal edit confirmation but it was not found`);
      return;
    }

    goalEditConfirmOnClose();
    confirmGoalEdit.acceptFunc(false);
    setConfirmGoalEdit(null);
  }

  const handleGoalEditConfirmationAccepted = () => {
    if (!confirmGoalEdit) return;
    confirmGoalEdit.acceptFunc(true);
    goalEditConfirmOnClose();
  }

  const handleGoalAmountChanged = async (id: number, newAmount: number, isIncome: boolean, skipConfirm?: boolean) => {
    if (!buckets) {
      logger.error(`Buckets have not been populated but edits are being made. edit bucket id: ${id}`);
      return;
    }

    const bucketPool = isIncome ? buckets.trackedIncome : buckets.tracked;

    const bucketToBeEdited = bucketPool.find((b) => b.id === id);
    if (!bucketToBeEdited) {
      logger.error(`Bucket to be edited was not found. id: ${id}`);
      return;
    }

    if (bucketToBeEdited.goal === newAmount) {
      // no changes
      return;
    }

    updateBucketWaitingAndErrorState(id, true);

    if ((newAmount === 0) && (utils.IsUserDefinedBucket(id)) && bucketToBeEdited.isParent) {
      setErrorMessage(`Cannot delete ${bucketToBeEdited.name} because it has child buckets`);
      updateBucketWaitingAndErrorState(id, false, `Cannot delete ${bucketToBeEdited.name} because it has child buckets`);
      return;
    }

    if (!skipConfirm && (newAmount === 0)) {
      setDeleteBucketAlert(bucketToBeEdited);
      alertOnOpen();
      return;
    }

    if (skipConfirm) {
      setDeleteBucketAlert(null);
      alertOnClose();
    }

    const completeGoalAmountChange = async (run: boolean) => {
      if (run) {
        // TODO: don't use userService directly
        let editResult = await userService.editBucket({ id, goal: newAmount });
        if (editResult.success) {
          const getResult = await userService.getBuckets({});
          if (getResult.success && getResult.data) {
            setErrorMessage(null);
            updateBuckets(getResult.data);
            return;
          }
          editResult.message = getResult.message;
        }
        setErrorMessage(editResult.message);
      }

      updateBucketWaitingAndErrorState(id, false);
    };

    const updatedStatus = checkIfNewGoalUpdatesParentGoalOrSelf(bucketToBeEdited, newAmount, bucketPool);
    if (updatedStatus !== null) {
      handleGetGoalEditConfirmation({
        ...bucketToBeEdited,
        updatedGoal: updatedStatus.goal,
        isParent: updatedStatus.isParent,
        acceptFunc: completeGoalAmountChange,
        goalSelected: newAmount,
        noChange: bucketToBeEdited.goal === updatedStatus.goal,
      });

      return;
    }

    completeGoalAmountChange(true);
  };

  const handleNewBudgetTracked = async (bucketid: number, amount: number, rollover?: boolean) => {
    if (Number.isNaN(amount)) {
      setModalError('The amount entered must be a number');
      return;
    }
    setModalLoader(true);

    let editResult = await userService.editBucket({ id: bucketid, goal: amount, rollover });
    if (editResult.success) {
      const getResult = await userService.getBuckets({});
      if (getResult.success && getResult.data) {
        setModalLoader(false);
        setModalError(null);
        updateBuckets([...getResult.data]);
        return;
      }
      editResult.message = getResult.message;
    }

    setModalLoader(false);
    setModalError(editResult.message);
  };

  const handleEditOrCreateBucket = async (serviceHandler: () => Promise<Result>, setLoader: typeof setCreateBucketLoader, setError: typeof setCreateBucketError) => {
    setLoader(true);

    let result = await serviceHandler();
    cache.invalidate('buckets');
    if (result.success) {
      const getResult = await userService.getBuckets({});
      if (getResult.success && getResult.data) {
        setError(null);
        setLoader(false);

        updateBuckets(getResult.data, true);
        return;
      }

      result.message = getResult.message;
    }

    setLoader(false);
    setError(result.message);
  };

  const handleDeleteBucketAlertClosed = () => {
    if (deleteBucketAlert?.id) {
      updateBucketWaitingAndErrorState(deleteBucketAlert.id, false);
    }

    setDeleteBucketAlert(null);
    alertOnClose();
  };

  const handleBucketEditSaved = (id: number, values: EditBucketValues) => {
    setEditBucketId(id);

    const completeEdit = (run?: boolean) => {
      if (run) {
        handleEditOrCreateBucket(() => userService.editBucket({ id, ...values }), setEditBucketLoader, setEditBucketError);
      }
    }

    const bucketPool = (values.isIncome ? buckets?.trackedIncome : buckets?.tracked) ?? [];
    const bucketToBeEdited = bucketPool.find((b) => b.id === id);
    console.log(values)
    console.log(bucketToBeEdited)

    if (!bucketToBeEdited) {
      logger.error(`The bucket being edited should exist. id: ${id}`);
      setEditBucketError('Failed to edit bucket');
      return;
    }

    if (values.goal && values.goal !== bucketToBeEdited.goal) {
      const updatedStatus = checkIfNewGoalUpdatesParentGoalOrSelf(bucketToBeEdited, values.goal, bucketPool);
      console.log(updatedStatus)
      if (updatedStatus !== null) {
        handleGetGoalEditConfirmation({
          ...bucketToBeEdited,
          updatedGoal: updatedStatus.goal,
          isParent: updatedStatus.isParent,
          acceptFunc: completeEdit,
          goalSelected: values.goal,
          noChange: bucketToBeEdited.goal === updatedStatus.goal,
        });

        return;
      }
    }

    completeEdit(true);
  };

  const handleCreateBucketSaved = (data: CreateBucketParams) => {
    handleEditOrCreateBucket(() => userService.createBucket({ ...data }), setCreateBucketLoader, setCreateBucketError);
  };

  useDataGetter([], userService.getBuckets, 'buckets', updateBuckets, setErrorMessage,
    { defaultErrorMessage: 'Could not retreive buckets', forceDefault: false, forceFetch: true }, {});

  const isDeletingBucket = deleteBucketAlert && utils.IsUserDefinedBucket(deleteBucketAlert.id);
  const actionName = isDeletingBucket ? 'delete' : 'untrack';
  const actionNamePastTense = isDeletingBucket ? 'deleted' : 'untracked';

  const returnRefForSpendProgress = React.useRef<HTMLDivElement>(null);

  const incomes = { tracked: buckets?.trackedIncome, untracked: buckets?.untrackedIncome };

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Budget</h3>
      </header>
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      {deleteBucketAlert &&
        <AlertDialog
          isOpen={alertIsOpen}
          leastDestructiveRef={cancelAlertRef}
          onClose={utils.EmptyFunction}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                {`${utils.CapitalizeFirstLetter(actionName)} '${deleteBucketAlert.name}' Bucket`}
              </AlertDialogHeader>

              <AlertDialogBody>
                <Text>Setting this bucket's goal to 0 will cause the bucket to be {actionNamePastTense}.</Text>
                <Text>Are you sure you want to {actionName} this bucket?</Text>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelAlertRef} onClick={handleDeleteBucketAlertClosed}>
                  Cancel
                </Button>
                <Button colorScheme={isDeletingBucket ? 'red' : 'orange'} onClick={() => handleGoalAmountChanged(deleteBucketAlert.id, 0, !!deleteBucketAlert.isIncome, true)} ml={3}>
                  Yes, {actionName}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      }
      {confirmGoalEdit &&
        <GoalEditConfirmationModal name={confirmGoalEdit.name} isParent={confirmGoalEdit.isParent} goalSelected={confirmGoalEdit.goalSelected}
          finalFocusRef={returnRefForSpendProgress} // send focus back to the edited component after pop-up exit
          parentName={confirmGoalEdit.parentName} updatedGoal={confirmGoalEdit.updatedGoal} shouldBeOpen={goalEditConfirmIsOpen} noChange={confirmGoalEdit.noChange}
          cancel={handleGoalEditConfirmationCancel} confirm={handleGoalEditConfirmationAccepted} />
      }
      <>
        <Text fontSize='3xl'>Income Buckets</Text>
        <CreateBucketModal
          buttonName='Create Income Bucket'
          initialValues={createIncomeBucketInitialValues}
          onModalClosed={() => { setCreateBucketError(null) }}
          onSaved={handleCreateBucketSaved}
          bucketList={parentBucketList?.income ?? null}
          errorMessage={createBucketError} loading={createBucketLoader}
        />
        {incomes?.tracked &&
          incomes.tracked.map((bucket, idx) => {
            return <Collapse key={idx} animateOpacity in={!bucket.hide}>
              <SpendProgress
                bucket={bucket} parentName={bucket.parentName} parentId={bucket.parent}
                bucketList={parentBucketList?.income ?? null}
                showCursor={!!bucket.hasTrackedChildren}
                loader={bucket.waitingForData}
                goalEditError={bucket.errorMessage}
                bucketEditError={editBucketId === bucket.id ? editBucketError : null} // only open the modal if it's the currently edited bucket
                refToThis={editBucketId === bucket.id ? returnRefForSpendProgress : undefined}
                editLoading={editBucketLoader}
                onCursorClicked={handleExpandParentBucket}
                onEditClosed={() => setEditBucketError(null)}
                onEditSaved={handleBucketEditSaved}
                onAmountChanged={(id, amt) => handleGoalAmountChanged(id, amt, true)} />
            </Collapse>
          })}
        <Text fontSize='2xl'>Untracked Income</Text>
        <Text>{incomes?.untracked && incomes?.untracked.length > 0 ? `Click an income to add it` : `All income has been budgeted`}</Text>
        <List spacing={3}>{incomes?.untracked &&
          incomes.untracked.map((bucket, idx) => <ListItem key={idx}>
            <ListIcon as={PlusCircleFill} color='green.500' />
            <ModalLink parentName={bucket.parentName} bucketid={bucket.id} {...bucket} loading={modalLoader} amount={bucket.expense}
              handleNewAmount={handleNewBudgetTracked} errorMessage={modalError} onModalClosed={() => { setModalError(null) }} />
          </ListItem>)}
        </List>
      </>
      <>
        <Text fontSize='3xl'>Tracked Expenses</Text>
        <CreateBucketModal
          buttonName='Create Expense Bucket'
          initialValues={createExpenseBucketInitialValues}
          onModalClosed={() => { setCreateBucketError(null) }}
          onSaved={handleCreateBucketSaved}
          bucketList={parentBucketList?.expense ?? null}
          errorMessage={createBucketError} loading={createBucketLoader}
        />
        {buckets?.tracked &&
          buckets.tracked.map((bucket, idx) => {
            return <Collapse key={idx} animateOpacity in={!bucket.hide}>
              <SpendProgress
                bucket={bucket} parentName={bucket.parentName} parentId={bucket.parent}
                bucketList={parentBucketList?.expense ?? null}
                showCursor={!!bucket.hasTrackedChildren}
                loader={bucket.waitingForData}
                goalEditError={bucket.errorMessage}
                bucketEditError={editBucketId === bucket.id ? editBucketError : null} // only open the modal if it's the currently edited bucket
                refToThis={editBucketId === bucket.id ? returnRefForSpendProgress : undefined}
                editLoading={editBucketLoader}
                onCursorClicked={handleExpandParentBucket}
                onEditClosed={() => setEditBucketError(null)}
                onEditSaved={handleBucketEditSaved}
                onAmountChanged={(id, amt) => handleGoalAmountChanged(id, amt, false)} />
            </Collapse>
          })}
        <Text fontSize='2xl'>Untracked Budgets</Text>
        <Text>{buckets?.untracked && buckets?.untracked.length > 0 ? `Click an expense to add it` : `All expenses have been budgeted`}</Text>
        <List spacing={3}>{buckets?.untracked &&
          buckets.untracked.map((bucket, idx) => <ListItem key={idx}>
            <ListIcon as={PlusCircleFill} color='green.500' />
            <ModalLink parentName={bucket.parentName} bucketid={bucket.id} {...bucket} loading={modalLoader} amount={bucket.expense}
              handleNewAmount={handleNewBudgetTracked} errorMessage={modalError} onModalClosed={() => { setModalError(null) }} />
          </ListItem>)}
        </List>
      </>
    </div>
  );
};

export default Budget;
