import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    IconButton,
    Input as ChkaraInput,
    InputGroup as ChakraInputGroup,
    InputProps,
    InputRightElement,
    useDisclosure,
    useMergeRefs,
    Stack,
    ButtonGroup,
    Button,
    VisuallyHidden,
    Icon,
} from '@chakra-ui/react';
import { FieldInputProps, FormikState, FormikValues } from 'formik';
import { PropsWithChildren, ReactNode, forwardRef, useRef } from 'react';
import { Facebook, Google, EyeSlashFill, EyeFill, X, TwitterX } from 'react-bootstrap-icons';
import { LoginFormValues, RegisterFormValues } from '../types/user.type';
import { Logo } from './icons';

interface FormProps<T> {
    form: FormikState<T>
    field: FieldInputProps<string | number | readonly string[] | undefined>
    label?: string
};

const oauthProviders = [
    { name: 'Google', icon: <Google /> },
    { name: 'X', icon: <TwitterX /> },
    { name: 'Facebook', icon: <Facebook /> },
];

export const OAuthButtonGroup = () => (
    <ButtonGroup variant="secondary" spacing="4">
        {oauthProviders.map(({ name, icon }) => (
            <Button key={name} flexGrow={1}>
                <VisuallyHidden>Sign in with {name}</VisuallyHidden>
                {icon}
            </Button>
        ))}
    </ButtonGroup>
);

export const FormHeader: React.FC<PropsWithChildren<{}>> = ({ children }) => (
    <Stack spacing="6">
        <Logo />
        <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
            {children}
        </Stack>
    </Stack>
);

export const PasswordFieldRefComp = <T extends FormikValues,>({ field, form, ...inputProps }: InputProps & FormProps<T>, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { isOpen, onToggle } = useDisclosure();
    const inputRef = useRef<HTMLInputElement>(null);
    const name: keyof T = field.name;
    const errorMessage = form.errors[name] as string | string[] | undefined;

    const mergeRef = useMergeRefs(inputRef, ref);
    const onClickReveal = () => {
        onToggle();
        if (inputRef.current) {
            inputRef.current.focus({ preventScroll: true })
        }
    };

    return (
        <FormControl isInvalid={form.touched[name] && !!errorMessage}>
            <FormLabel htmlFor="password">Password</FormLabel>
            <ChakraInputGroup>
                <InputRightElement>
                    <IconButton
                        variant="text"
                        aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                        icon={isOpen ? <EyeSlashFill /> : <EyeFill />}
                        onClick={onClickReveal}
                    />
                </InputRightElement>
                <ChkaraInput
                    {...field}
                    id="password"
                    ref={mergeRef}
                    name="password"
                    type={isOpen ? 'text' : 'password'}
                    autoComplete="current-password"
                    placeholder='Enter password'
                    required
                    {...inputProps}
                />
            </ChakraInputGroup>
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
    );
};

// TODO: create a RefInput & replace chakra inpout with that
interface ExtraProps {
    removeLabel?: boolean
}
export const Input = <T extends FormikValues,>({ field, form, label, removeLabel, ...inputProps }: ExtraProps & InputProps & FormProps<T>) => {
    const name: keyof T = field.name;
    const errorMessage = form.errors[name] as string | string[] | undefined;
    const showLabel = !removeLabel;
    return (
        <FormControl isInvalid={form.touched[name] && !!errorMessage}>
            {showLabel && <FormLabel htmlFor={name}>{label ?? name}</FormLabel>}
            <ChkaraInput
                {...field}
                id={name}
                name={name}
                {...inputProps}
                type={inputProps.type || 'text'}
                placeholder={inputProps.placeholder ?? `Enter ${String(name)}`}
            />
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
    );
};


interface InputGroupProps extends InputProps {
    leftComponent?: ReactNode
    rightComponent?: ReactNode
};
export const DecoratedInput = <T extends FormikValues,>({ field, form, label, leftComponent, rightComponent, ...inputProps }: InputGroupProps & FormProps<T>) => {
    const name: keyof T = field.name;
    const errorMessage = form.errors[name] as string | string[] | undefined;

    return (
        <FormControl isInvalid={form.touched[name] && !!errorMessage}>
            <FormLabel htmlFor={name}>{label ?? name}</FormLabel>
            <ChakraInputGroup >
                {leftComponent}
                <ChkaraInput
                    {...field}
                    id={name}
                    name={name}
                    {...inputProps}
                    type={inputProps.type || 'text'}
                    placeholder={inputProps.placeholder ?? `Enter ${String(name)}`}
                />
                {rightComponent}
            </ChakraInputGroup>
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
    );
};
DecoratedInput.displayName = 'DecoratedInput';

export const RefDecoratedInput = <T extends FormikValues,>({ field, form, label, leftComponent, rightComponent, ...inputProps }: InputGroupProps & FormProps<T>, ref: React.ForwardedRef<HTMLInputElement>) => {
    const name: keyof T = field.name;
    const errorMessage = form.errors[name] as string | string[] | undefined;
    const mergeRef = useMergeRefs(useRef<HTMLInputElement>(null), ref);

    return (
        <FormControl isInvalid={form.touched[name] && !!errorMessage}>
            <FormLabel htmlFor={name}>{label ?? name}</FormLabel>
            <ChakraInputGroup >
                {rightComponent}
                <ChkaraInput
                    ref={ref ? mergeRef : undefined}
                    {...field}
                    id={name}
                    name={name}
                    {...inputProps}
                    type={inputProps.type || 'text'}
                    placeholder={inputProps.placeholder ?? `Enter ${String(name)}`}
                />
                {rightComponent}
            </ChakraInputGroup>
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
    );
};
RefDecoratedInput.displayName = 'RefDecoratedInput';

export const LoginPasswordField = forwardRef<HTMLInputElement, InputProps & FormProps<LoginFormValues>>(PasswordFieldRefComp);
export const RegisterPasswordField = forwardRef<HTMLInputElement, InputProps & FormProps<RegisterFormValues>>(PasswordFieldRefComp);