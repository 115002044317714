import { Link, useOutlet } from "react-router-dom";
import { Props } from "../types/react.type";
import { logger } from "../common/utils";
import routenames from "../common/routenames";
import { IAuthenticatedCtx, useAuth } from "../services/auth.service";

// interface AppLayoutProps extends Props {
// };

export const AppLayout: React.FC<{}> = ({ }) => {
    const outlet = useOutlet();
    const auth = useAuth() as IAuthenticatedCtx;

    const handleLogout = () => {
        if (!auth.isLoggedIn) {
            logger.error(`Error in routing. In AppLayout with invalid login state`);
            return;
        }

        auth.logout();
    };

    return (
        <div>
            <nav className="navbar navbar-expand navbar-dark bg-dark">
                <Link to={"/"} className="navbar-brand">
                    Owo App
                </Link>
                <div className="navbar-nav mr-auto">
                    <li className="nav-item">
                        <Link to={routenames.app} className="nav-link">
                            Profile
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={routenames.budget} className="nav-link">
                            Budget
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={routenames.transactions} className="nav-link">
                            Transactions
                        </Link>
                    </li>
                </div>

                {(
                    <div className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link to={"/profile"} className="nav-link"> {auth.user.firstName}</Link>
                        </li>
                        <li className="nav-item">
                            <a href="/login" className="nav-link" onClick={handleLogout}>
                                LogOut
                            </a>
                        </li>
                    </div>
                )}
            </nav>
            <div className="container mt-3">

                {outlet}
            </div>
        </div>
    );
}