import { FC, forwardRef, useCallback, useState } from 'react';

import {
  useTellerConnect,
  TellerConnectOnSuccess,
  TellerConnectOnExit,
  TellerConnectOptions,
  TellerConnectEnrollment,
} from 'teller-connect-react';
import { data } from '../services/cache.service';
import { logger } from '../common/utils';
import { TellerParams } from '../types/user.type';
import { Button, ButtonProps } from '@chakra-ui/react';

const environment = ((process.env.REACT_APP_TELLER_ENV === 'production') ||
  (process.env.REACT_APP_TELLER_ENV === 'development') ||
  (process.env.REACT_APP_TELLER_ENV === 'sandbox')) ? process.env.REACT_APP_TELLER_ENV : 'sandbox';

export interface EnrollHandlers {
  onTellerStart: () => void
  onTellerIncomplete: () => void
  onTellerSuccess: (enrollment: TellerConnectEnrollment, environment: string) => void
}

interface EnrollProps extends EnrollHandlers, ButtonProps {
  name?: string
  loading?: boolean
  enrollmentId?: string
  institution?: string
}

const Enroll: FC<EnrollProps> = forwardRef(({ name, loading, enrollmentId, institution, onTellerIncomplete, onTellerSuccess, onTellerStart, ...buttonProps }, ref: React.ForwardedRef<HTMLInputElement>) => {
  const [tellerConfig, setAppId] = useState<TellerParams | null>(null);
  var [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onSuccess = useCallback<TellerConnectOnSuccess>((enrollment: TellerConnectEnrollment) => onTellerSuccess(enrollment, environment), []);
  const onExit = useCallback<TellerConnectOnExit>(onTellerIncomplete, []);

  data.useSauce(setAppId, setErrorMessage,
    { defaultErrorMessage: 'Cannot connect to your accounts at the moment. Please try again later.', forceDefault: true });

  const config: TellerConnectOptions = {
    applicationId: tellerConfig?.sauce || '',
    onSuccess,
    onExit,
    environment,
    selectAccount: "multiple",
    nonce: tellerConfig?.mixer,
    enrollmentId: enrollmentId || '',
    institution: institution || '',
  };

  const { open, ready, error } = useTellerConnect(config);
  if (error) {
    logger.log(`Teller connect error: ${error.message}`);
    errorMessage = 'Could not connect to accounts service';
    // todo: pop toast of error
  }

  loading = loading || !ready || !tellerConfig;
  return <Button
    onClick={() => { onTellerStart(); open(); }}

    isDisabled={errorMessage != null}
    isLoading={loading} spinnerPlacement='start' loadingText='Loading'
    colorScheme='blue'
    {...buttonProps}
    ref={ref}
  >
    {name || 'Connect a bank account'}
  </Button >
});

export default Enroll;
