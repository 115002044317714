import { Result, api, handleFailedApiCall } from "../common/api";
import { REPAIR_MODE } from "../common/utils";
import { EmptyRequest, RepairTransactionsResponse } from "../types/server.type";

const repairTransactions = async (): Promise<Result<number>> => {
    try {
        const response = await api.post<EmptyRequest, RepairTransactionsResponse>("repair/transactions", {});
        return { success: true, message: '', data: response.data.numTransactionsRemoved };
    } catch (error) {
        let errorResult = handleFailedApiCall(error, 'Failed to repair transactions');
        return { ...errorResult };
    }
};

const RepairService = () => (REPAIR_MODE ? ({
    repairTransactions,
}) : null);

export default RepairService;