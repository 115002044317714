import SuperTokens from "supertokens-auth-react";
import ThirdParty, { Google, Facebook, Apple } from "supertokens-auth-react/recipe/thirdparty";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";
import { API_URL, APP_URL, API_GATEWAY } from "../common/api";

export const InitializeSuperTokens = () => SuperTokens.init({
    appInfo: {
        appName: "Owo App",
        apiDomain: API_URL,
        apiBasePath: "/auth",
        apiGatewayPath: API_GATEWAY,
        websiteDomain: APP_URL,
        websiteBasePath: "/auth",
    },
    recipeList: [
        ThirdParty.init({
            signInAndUpFeature: {
                providers: [
                    Google.init(),
                    Facebook.init(),
                    Apple.init(),
                ]
            }
        }),
        EmailPassword.init(),
        Session.init()
    ]
});
