import React, { FC, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { ExpanderComponentProps } from "react-data-table-component/dist/DataTable/types";
import { EditText, onSaveProps } from 'react-edit-text';
import { Alert, AlertIcon, AlertTitle, Button, Skeleton, Spinner, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { SignIntersectionY } from "react-bootstrap-icons";
import Fuse from "fuse.js";
import 'react-edit-text/dist/index.css';

import Utils, { FromUrlQuery, logger } from "../common/utils";
import { ParentBucket, SplitTransactionValues, Transaction, TransactionList } from "../types/server.type";
import cache, { data, useDataGetter } from "../services/cache.service";
import userService, { CreateBucketParams } from "../services/user.service";
import { CategoryPickerOption, CategoryPicker } from "../components/CategoryPicker.component";
import { FilterDrawer } from "../components/FilterDrawer.component";
import { CreateBucketModal } from "../components/CreateBucketModal.component";
import { useParams } from "react-router-dom";
import { TransactionFilterParams } from "../types/user.type";
import { SplitTransactionModal } from "../components/SplitTransactionModal";
import { errorToast, successToast } from "../common/constants";

const ExpandedRowcontent: React.FC<ExpanderComponentProps<Transaction> & { openSplitModal?: (tx: Transaction) => void }> = ({ data: transaction, openSplitModal }) => (
  <div>
    <Text>{transaction.description}</Text>
    <Text>{transaction.bucketname}</Text>
    <Text>{transaction.bucketid}</Text>
    <Text>{transaction.id}</Text>
    <Button onClick={() => openSplitModal!(transaction)} leftIcon={<SignIntersectionY />} colorScheme='teal' variant='solid'></Button>

  </div>
);

type EditableTextProps = {
  initialText: string;
  onAmountChanged: (amount: number) => void
};

const EditableText: React.FunctionComponent<EditableTextProps> = ({ initialText, onAmountChanged }) => {
  const isNeg = (initialText.length > 0) && (initialText[0] === '-');
  const [value, setValue] = useState(initialText);
  // const [showEditButton, setShowEditButton] = useState(editing);
  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let validatedValue = e.target.value;
    function isNumber(n: any) {
      return !isNaN(n - n);
    }
    if (isNumber(validatedValue))
      setValue(((validatedValue)));
    else
      setValue(value)
  };

  const handleChange = ({ value }: onSaveProps) => {
    // todo validate
    onAmountChanged(parseFloat(value));
  }

  return (
    <EditText
      name='textbox3'
      value={value}
      onChange={handleInput}
      onSave={handleChange}
    // editButtonProps={{ style: { marginLeft: '5px', width: 16 }, hidden: !editing }}
    // showEditButton={true}
    />
  );
}

const LoadingTransactions: React.FC<{}> = () => (
  <Stack width='100%'>
    <Alert
      variant='subtle'
      colorScheme="gray"
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
      height='200px'
    >
      <Spinner boxSize='40px' mr={0} />
      <AlertTitle mt={4} mb={1} fontSize='lg'>
        Fetching Transactions..
      </AlertTitle>
    </Alert>
    <Stack spacing={3}>
      {Array.from(new Array(10), (_, id) => <Skeleton key={id} height='40px' speed={1.2} endColor="gray.400" />)}
    </Stack>
  </Stack>
);

const EmptyTransactionList: React.FC<{ hasFilter: boolean, onOpenFilter: () => void, onClearFilters: () => void }> =
  ({ hasFilter, onOpenFilter, onClearFilters }) => (
    <Alert status='warning'>
      <AlertIcon />
      {hasFilter ? "No transactions matching the applied filters." : "No transactions found"}
      <Stack direction='row' spacing={4} align='center'>
        <Button ml={3} variant='link' onClick={onOpenFilter}>Open Filters</Button>
        <Button variant='link' onClick={onClearFilters}>Clear All Filters</Button>
      </Stack>

    </Alert>
  );

const getBucketName = (
  id: number,
  bucketMapping: { [key: number]: string },
  buckets: ParentBucket[]) => {
  // if it's a default bucket, return from the default map
  const name = bucketMapping[id];
  if (name) {
    return name;
  }

  if (!Utils.IsUserDefinedBucket(id)) {
    logger.error(`Default bucket id not found in default mapping. ID: ${id}`);

    // we still attempt to check the bucket list nonetheless
  }

  // we are still waiting for the buckets
  if (buckets.length === 0) return null;

  // check each bucket and its children
  for (const bucket of buckets) {
    if (bucket.id === id) {
      return bucket.name;
    }

    if (!bucket.children) continue;

    for (const child of bucket.children) {
      if (child.id === id) {
        return child.name;
      }
    }
  }

  // This is a bad case where an id could not be mapped
  logger.error(`Bucket with id: ${id}, was not found`);
  return undefined;
}

const createBucketMenuList = (
  bucketStructure: { [key: number]: number[] } | null,
  bucketMapping: { [key: number]: string } | null,
  buckets: ParentBucket[],
) => {
  if (!bucketStructure || !bucketMapping) return null;
  let menuList: CategoryPickerOption[] = [];

  for (let [key, children] of Object.entries(bucketStructure)) {
    const id = Number(key);
    if (Number.isNaN(id)) {
      logger.error(`Invalid bucket id in structure`);
      continue;
    }

    const bucketName = getBucketName(id, bucketMapping, buckets);

    // Skip any buckets that couldn't be resolved
    if (bucketName === undefined) continue;

    // If null, it means the bucket list is still
    // loading from the server so still waiting on user-defined buckets
    if (bucketName === null) {
      // menuList.push(null);
    } else {
      // menuList.push({ label: bucketName })
      menuList.push({ label: bucketName, value: id, isParent: children?.length > 0 });
    }

    for (const childId of children) {
      const bucketName = getBucketName(childId, bucketMapping, buckets);

      // Skip any buckets that couldn't be resolved
      if (bucketName === undefined) continue;

      // If null, it means the bucket list is still
      // loading from the server so still waiting on user-defined buckets
      if (bucketName === null) {
        // menuList.push(null);
      } else {
        // menuList.push({ label: bucketName })
        menuList.push({ label: bucketName, value: childId });
      }
    }
  }

  return menuList;
};

type transactionCreateBucketParams = { transaction: Transaction, bucketName: string };

const getInitialCreateBucketValues = ({ bucketName, transaction }: transactionCreateBucketParams): CreateBucketParams => ({
  name: bucketName,
  rollover: false,
  goal: Utils.GetSuggestedBudgetAmount(transaction.amount),
  parentId: 0,
  isIncome: undefined,
});

const getParentBucketListFromTransaction = (tx: Transaction, buckets: ParentBucket[]): readonly CategoryPickerOption[] | null => {
  if (buckets.length === 0) return null;

  if (tx.amount < 0) {
    // only show expense buckets for an expense
    return buckets.filter(bucket => !bucket.isIncome).map((bucket) => ({ label: bucket.name, value: bucket.id }));
  }

  // display all buckets for credit because it could be under expense or income
  return buckets.map((bucket) => ({ label: bucket.name, value: bucket.id }));
}

const getParentTransaction = (tx: Transaction, transactions: TransactionList): Transaction | undefined => {
  if (tx.parentIndex === null || tx.parentIndex === undefined) {
    return tx;
  }

  return transactions[tx.parentIndex];
};

const getSplitTransactions = (tx: Transaction, transactions: TransactionList): SplitTransactionValues[] => {
  let nextSplit = tx;
  const splits: SplitTransactionValues[] = [{ ...nextSplit, amount: Math.abs(nextSplit.amount) }];

  while (nextSplit.nextTransactionIndex !== undefined && nextSplit.nextTransactionIndex !== null) {
    const prev = nextSplit;
    nextSplit = transactions[nextSplit.nextTransactionIndex];
    if (!nextSplit) {
      logger.error(`Could not find transaction on index: ${prev.nextTransactionIndex}, next-transaction to, txId: ${prev.id}`);
      break;
    }
    splits.push({ ...nextSplit, amount: Math.abs(nextSplit.amount) });
  }

  // There has to be at least 2 splits
  splits.push({ amount: 0 });

  return splits;
};

const emptyFilterParams = {
  credit: true,
  debit: true,
  buckets: [],
  dateRange: { from: undefined, to: undefined },
  description: '',
};

const geEmptyFilterParamsWithCurrentMonth = (filterQuery: string | undefined): TransactionFilterParams & { shouldFilter: boolean } => {
  const shouldFilter = filterQuery !== undefined;
  if (!filterQuery) {
    return { ...emptyFilterParams, shouldFilter };
  }

  const filterParamsFromQuery = FromUrlQuery(filterQuery);
  const to = new Date();
  to.setHours(0, 0, 0, 0); // remove time from date
  const from = new Date(to.getFullYear(), to.getMonth(), 1);
  if (!filterParamsFromQuery.dateRange) {
    filterParamsFromQuery.dateRange = { from, to };
  }
  return { ...emptyFilterParams, ...filterParamsFromQuery, shouldFilter };
};

const fuseOptions = {
  keys: [
    "description",
  ]
};
const getTransactionsMatchingFilter = (filterParams: TransactionFilterParams, transactions: TransactionList | null) => {
  if (!transactions) return [];

  // we start with a text matching
  let meetsDescription: Set<number> | null = null;
  if (filterParams.description.length > 0) {
    const fuse = new Fuse(transactions, fuseOptions);
    meetsDescription = new Set(fuse.search(filterParams.description).map((res) => res.refIndex));
  }

  const meetsAllFilters: Transaction[] = [];
  for (let [i, tx] of transactions.entries()) {
    if (meetsDescription && !meetsDescription.has(i)) {
      continue;
    }

    if (filterParams.credit && !filterParams.debit && tx.amount < 0) {
      continue;
    }

    if (filterParams.debit && !filterParams.credit && tx.amount > 0) {
      continue;
    }

    if (filterParams.buckets.length > 0 && !filterParams.buckets.includes(tx.bucketid)) {
      continue;
    }

    const txDate = new Date(tx.date);
    if (!Number.isNaN(txDate.getDate())) {
      const from = filterParams.dateRange?.from;
      if (from && (txDate < from)) {
        continue;
      }
    }

    const to = filterParams.dateRange?.to;
    if (to && (txDate > to)) {
      continue;
    }

    meetsAllFilters.push(tx);
  }

  return meetsAllFilters;
};

const Transactions: FC<{}> = () => {
  const [transactions, setTransactions] = useState<TransactionList | null>(null);
  const [bucketStructure, setBucketStructure] = useState<{ [key: number]: number[] } | null>(null);
  const [bucketMapping, setBucketMapping] = useState<{ [key: number]: string } | null>(null);
  const [buckets, setBuckets] = useState<ParentBucket[]>([]);

  // create bucket states
  const [createBucketParams, setCreateBucketParams] = useState<transactionCreateBucketParams | null>(null);
  const [createBucketLoader, setCreateBucketLoader] = useState<boolean>(false);
  const [createBucketError, setCreateBucketError] = useState<string | null>(null);

  // filtering
  const { filter: filterQuery } = useParams();
  const defaultFilterParams = geEmptyFilterParamsWithCurrentMonth(filterQuery);
  const [filterParams, setFilterParams] = useState<TransactionFilterParams & { shouldFilter: boolean }>(defaultFilterParams);
  const [openFilterDrawer, setOpenFilterDrawer] = useState<boolean>(false);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  // error display
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [minorErrorMessage, setMinorMessage] = useState<string | null>(null);
  const toast = useToast();
  const showErrorInToast = ({ title = 'Error', description = 'An unexpected error has occured' }) => toast({
    title,
    description,
    ...errorToast,
  });

  const showSuccessInToast = ({ title = 'Success', description = 'Operation completed successfully' }) => toast({
    title,
    description,
    ...successToast,
  });

  // split transactions
  const [txToSplit, setTxToSplit] = useState<Transaction | null>(null);
  const [splitTxLoader, setSplitTxLoader] = useState<boolean>(false);
  const { isOpen: splitModalIsOpen, onOpen: onSplitModalOpen, onClose: onSplitModalClosed } = useDisclosure();


  const updateBuckets = (buckets: ParentBucket[]) => {
    setBuckets((oldBuckets: ParentBucket[]) => {
      if (Utils.DeepEqual(oldBuckets, buckets)) {
        // no new updates
        return oldBuckets;
      }
      return buckets;
    });
  };

  data.useTransactions([], setTransactions, setErrorMessage, {
    defaultErrorMessage: 'No Transactions :(',
    forceDefault: false,
  });

  useDataGetter([], userService.getBucketMapping, 'bucketmapping', setBucketMapping, setErrorMessage,
    { defaultErrorMessage: 'Could not retrieve bucket mapping', forceDefault: false, forceFetch: false });

  useDataGetter([buckets], userService.getBucketStructure, 'bucketstructure', setBucketStructure, setErrorMessage,
    { defaultErrorMessage: 'Could not retrieve bucket structure', forceDefault: false, forceFetch: true }); // forcefetch: true

  useDataGetter([], userService.getBuckets, 'buckets', updateBuckets, setErrorMessage,
    { defaultErrorMessage: 'Could not retreive buckets', forceDefault: false, forceFetch: false }, {}); // forcefetch: true


  const handleBucketSelect = async (newBucket: CategoryPickerOption, tx: Transaction) => {
    const bucketid = Number(newBucket.value);
    if (Number.isNaN(bucketid)) {
      setMinorMessage('Invalid bucket selected.');
      return;
    }
    // TODO: set loader
    // setModalLoader(true);

    if (tx.bucketid === bucketid) {
      logger.error(`Bucket is unchanged: ${newBucket.label}`)
      return;
    }

    const editResult = await userService.editTransactionBucket(tx.id, bucketid);
    if (editResult.success) {
      cache.invalidate('buckets');
      const getResult = await userService.getTransactions();
      if (getResult.success && getResult.data) {
        setTransactions(getResult.data);
        setMinorMessage(null);
        return;
      }
      editResult.message = getResult.message;
    }

    setMinorMessage(editResult.message);
    // TODO: set loader
    // setModalLoader(false);
  };

  const handleCreateBucketSelect = (bucketName: string, tx: Transaction) => {
    // TODO: validate
    setCreateBucketParams({ bucketName, transaction: tx })
  }

  const handleCreateBucketSaved = async (data: CreateBucketParams) => {
    setCreateBucketLoader(true);

    let result = await userService.createBucket({ ...data, transactionId: createBucketParams?.transaction.id });
    cache.invalidate('buckets');
    if (result.success) {
      setCreateBucketError(null);
      setCreateBucketLoader(false);
      setCreateBucketParams(null);

      const [getBucketResult, getTransactionsResult] = await Promise.all([
        userService.getBuckets({}),
        userService.getTransactions()
      ]);

      const allSucceeded = getBucketResult.success && getBucketResult.data &&
        getTransactionsResult.success && getTransactionsResult.data;
      if (allSucceeded) {
        const newBuckets = getBucketResult.data;
        if (newBuckets) {
          updateBuckets(newBuckets);
          setMinorMessage(null);
        } else {
          logger.error('There should always be buckets');
          setMinorMessage('Failed to fetch updated buckets');
        }

        const newTransactions = getTransactionsResult.data;
        if (newTransactions) {
          setTransactions(newTransactions);
          setMinorMessage(null);
        } else {
          logger.error('There should always be transactions after a transaction edit');
          // TODO: make minor message an array or map so it doesn't have to override previous message
          setMinorMessage('Failed to fetch updated transactions');
        }
      }
      result.message = (getBucketResult.success) ? getTransactionsResult.message : getBucketResult.message;
      // TODO: add both errors if there'e more than one
      setMinorMessage(result.message);
      return;
    }

    setCreateBucketLoader(false);
    setCreateBucketError(result.message);
  };

  const handleCreateBucketModalClosed = () => {
    setCreateBucketError(null);
    setCreateBucketParams(null);
  };

  const handleFilter = (filterParamsUpdate: TransactionFilterParams) => {
    const shouldFilter = !Utils.DeepEqual(filterParamsUpdate, emptyFilterParams);
    const newFilterParams = { ...filterParamsUpdate, shouldFilter };
    setFilterParams((prevFilterParams) => {
      if (Utils.DeepEqual(prevFilterParams, newFilterParams)) {
        return prevFilterParams;
      }

      setResetPaginationToggle(!resetPaginationToggle);
      return newFilterParams;
    });
    setOpenFilterDrawer(false);
  };

  const handleClearFilters = () => {
    handleFilter(emptyFilterParams);
  }

  const handleSplitTransactionCompleted = async (details: { splits: SplitTransactionValues[]; }) => {
    if (!txToSplit) {
      logger.error(`Bad state: could not find transaction being split`);
      return;
    }

    setSplitTxLoader(true);
    const result = await userService.splitTransaction({ splits: details.splits, transactionId: txToSplit.id });
    if (result.success) {
      showSuccessInToast({ description: 'Transaction split complete' });
      cache.invalidate('transactions');
      const getResult = await userService.getTransactions();
      if (getResult.success && getResult.data) {
        setTransactions(getResult.data);
        setSplitTxLoader(false);
        handleSplitModalClosed();
        return;
      }
      result.message = getResult.message;
    }

    setSplitTxLoader(false);
    setMinorMessage(result.message);
  };

  const handleSplitModalClosed = () => {
    setTxToSplit(null);
    setMinorMessage(null);
    onSplitModalClosed();
  };

  const handleSplitModalOpened = (tx: Transaction) => {
    const txOrParent = getParentTransaction(tx, transactions!); // transactions is not null in this handler
    if (!txOrParent) {
      logger.error(`Failed to find parent transaction. TxID: ${tx.id}, parentIdx: ${tx.parentIndex}`);
      showErrorInToast({ description: 'Failed to split transaction.' });
      return;
    }

    setTxToSplit(txOrParent);
    onSplitModalOpen();
  }

  const bucketOptions = createBucketMenuList(bucketStructure, bucketMapping, buckets);

  const columns: TableColumn<Transaction>[] = [
    {
      id: 'date',
      name: "Date",
      selector: (tx: Transaction) => (tx.date),
      format: (tx: Transaction) => Utils.GetFriendlyDate(tx.date),
      sortable: true,
    },
    {
      id: 'description',
      name: "Description",
      selector: (tx: Transaction) => tx.description,
      sortable: true,
    },
    {
      id: 'amount',
      name: "Amount",
      sortable: true,
      sortFunction: (tx1, tx2) => tx1.amount - tx2.amount,
      cell: (tx) => <EditableText initialText={Utils.GetFriendlyAmount(tx.amount)} key={tx.amount} onAmountChanged={() => { }} />,
      conditionalCellStyles: [{
        when: (row) => (row.amount > 0),
        style: {
          color: 'green',
        }
      }],
    },
    {
      id: 'category',
      name: "Category",
      cell: (tx) => (
        <CategoryPicker
          handleCreate={handleCreateBucketSelect} onSelectedBucketChange={handleBucketSelect}
          options={bucketOptions} value={tx.bucketid} context={tx} placeholder='Select Category...'
        />
      ),
      sortable: true,
      sortFunction: (tx1, tx2) => Utils.SortByObjectName({ name: tx1.bucketname }, { name: tx2.bucketname }),
    },
  ];

  const displayedTransactions = filterParams.shouldFilter ? getTransactionsMatchingFilter(filterParams, transactions) : (transactions ?? []);

  const pending = (errorMessage === null) && (transactions === null);
  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Transactions</h3>
      </header>
      {(errorMessage || minorErrorMessage) && (
        <Alert status='error' mb={4}>
          <AlertIcon /> {errorMessage || minorErrorMessage}
        </Alert>
      )}
      <FilterDrawer options={bucketOptions} initialValues={filterParams} forceOpen={openFilterDrawer}
        onFilter={handleFilter} onCloseDrawer={() => setOpenFilterDrawer(false)} />
      {txToSplit && <SplitTransactionModal
        transaction={txToSplit} errorMessage={minorErrorMessage} bucketList={bucketOptions || null}
        open={splitModalIsOpen} loading={splitTxLoader}
        splits={getSplitTransactions(txToSplit, transactions!)} // transactions is not null when txToSplit is set
        onSaved={handleSplitTransactionCompleted} onModalClosed={handleSplitModalClosed} />
      }
      {!errorMessage && <DataTable
        columns={columns}
        data={displayedTransactions}
        defaultSortFieldId='date'
        defaultSortAsc={false}
        fixedHeader
        responsive
        highlightOnHover
        pointerOnHover

        pagination
        paginationPerPage={25}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        paginationResetDefaultPage={resetPaginationToggle}

        selectableRows
        selectableRowsHighlight
        onSelectedRowsChange={(x) => { }}
        // noContextMenu
        contextMessage={{ singular: 'transaction', plural: 'transactions', message: 'selected' }}

        expandableRows
        expandableRowsComponentProps={{ openSplitModal: handleSplitModalOpened }}
        expandableRowsComponent={ExpandedRowcontent}

        progressPending={pending} // TODO: use custom loader
        progressComponent={<LoadingTransactions />}
        noDataComponent={<EmptyTransactionList hasFilter={filterParams.shouldFilter} onOpenFilter={() => setOpenFilterDrawer(true)} onClearFilters={handleClearFilters} />}

        title="Transactions from Table"
      />}
      {createBucketParams && <CreateBucketModal
        buttonName='Create Expense Bucket'
        initialValues={getInitialCreateBucketValues(createBucketParams)}
        onModalClosed={handleCreateBucketModalClosed}
        onSaved={handleCreateBucketSaved}
        bucketList={getParentBucketListFromTransaction(createBucketParams.transaction, buckets)}
        errorMessage={createBucketError} loading={createBucketLoader}
        forceOpen={true}
      />}
    </div>
  );
};

export default Transactions;