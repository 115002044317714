import React, { FC } from "react";
import {
    Button, Checkbox, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftElement,
    Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
    Spinner, Text, useDisclosure
} from '@chakra-ui/react';
import { Field, Form, Formik, FormikHelpers } from "formik";

import utils from "../common/utils";

// TODO(FEATURE) Allow rollover from prev date
interface ModalLinkProps {
    bucketid: number;
    name: string;
    parentName?: string;
    amount: number;
    rollover: boolean;
    isIncome?: boolean;
    loading?: boolean;
    errorMessage: string | null;
    handleNewAmount: (bucketid: number, amount: number, rollover: boolean) => void;
    onModalClosed: () => void;
}
export const ModalLink: FC<ModalLinkProps> = ({ bucketid, name, parentName, amount, handleNewAmount, errorMessage, onModalClosed, loading, rollover, isIncome }) => {
    type ModalValues = {
        amount: number;
        rollover: boolean;
    };
    const { isOpen, onOpen, onClose } = useDisclosure();
    const amountInputRef = React.useRef<HTMLInputElement>(null);

    const handleSaved = (values: ModalValues, actions: FormikHelpers<ModalValues>) => {
        // TODO: figure out formik validations so the validation can be done in this node
        const amount = Number(values.amount);
        if (values.amount) {
            handleNewAmount(bucketid, amount, !!values.rollover);
            onClose();
        }
    };

    const handleClosed = () => {
        onModalClosed();
        onClose();
    };

    const displayAmount = utils.GetFriendlyAmount(amount);
    const displayName = parentName ? `${parentName} >> ${name}: $${displayAmount}` : `${name}: $${displayAmount}`;
    return (
        <>
            <Link onClick={onOpen} as='u'>{displayName}</Link>
            <Modal
                isCentered
                onClose={handleClosed}
                isOpen={isOpen || errorMessage !== null}
                motionPreset='slideInBottom'
                initialFocusRef={amountInputRef}>
                <ModalOverlay />
                <Formik
                    initialValues={{ amount: utils.GetSuggestedBudgetAmount(amount), rollover: rollover }}
                    onSubmit={handleSaved}
                >
                    <Form>
                        <ModalContent>
                            <ModalHeader>{name}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody pb={6}>
                                <Text fontWeight='bold' mb='1rem'>What would you like to set the budget of '{name}' to?</Text>

                                <Field name='amount'>
                                    {({ field, form }: any) => {
                                        return (
                                            <FormControl isInvalid={errorMessage !== null || form.errors.amount}>
                                                <FormLabel>Amount</FormLabel>
                                                <InputGroup>
                                                    <InputLeftElement pointerEvents='none' color='gray.300' fontSize='1.2em'>$</InputLeftElement>
                                                    <Input type="number" errorBorderColor='crimson' {...field} placeholder='Enter amount' ref={amountInputRef} />
                                                </InputGroup>
                                                <FormErrorMessage>{errorMessage || form.errors.amount}</FormErrorMessage>
                                            </FormControl>
                                        );
                                    }}
                                </Field>
                                {!isIncome && <Field name='rollover' validate={() => { }}>
                                    {({ field, form }: any) => (
                                        <FormControl><Checkbox {...field}>Rollover</Checkbox></FormControl>
                                    )}
                                </Field>}
                            </ModalBody>
                            <ModalFooter>
                                {loading ? <Spinner marginRight={'40px'} /> : <Button type="submit" colorScheme='blue' mr={3}>Save</Button>}
                                <Button onClick={handleClosed}>Cancel</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};
