import React, { FC } from "react";
import { useDisclosure, Button, Drawer, Stack, Textarea, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, FormLabel, Input, InputGroup, InputLeftAddon, InputRightAddon, Select, DrawerFooter, Box, AccordionPanel, AccordionItem, Accordion, AccordionButton, AccordionIcon, Checkbox, CheckboxGroup, FormControl, FormErrorMessage, Collapse } from "@chakra-ui/react";
import { DateRange, DayPicker, DayPickerBase } from "react-day-picker";
import { ArrowRight, ChevronDoubleRight, FilterSquare, Funnel, PlusSquareFill, X, XLg } from "react-bootstrap-icons";

import "react-day-picker/dist/style.css";
import { MultiCategoryPicker, MultiCategoryPickerProps } from "./CategoryPicker.component";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { UiColors } from "../common/constants";
import utils from "../common/utils";
import { TransactionFilterParams, TransactionFilterFormValues } from "../types/user.type";

type DatePickerProps = {
    onChange: (range?: DateRange) => void
    value?: DateRange
} & Pick<DayPickerBase, 'disabled'>;
function DatePicker({ onChange, value, disabled }: DatePickerProps) {
    const { isOpen, onToggle } = useDisclosure();
    const from = value?.from ? value.from.toLocaleDateString() : 'FROM';
    let to = value?.to ? value.to.toLocaleDateString() : 'TO';

    return (
        <>
            <Button onClick={onToggle}>{`${from} - ${to}`}</Button>
            <Collapse in={isOpen} animateOpacity>
                <Box>
                    <DayPicker mode="range" selected={value} onSelect={onChange} disabled={disabled} />
                </Box>
            </Collapse>
        </>
    );
};

export interface FilterDrawerProps extends Pick<MultiCategoryPickerProps, 'options'> {
    onFilter: (params: TransactionFilterParams) => void
    initialValues: TransactionFilterParams
    forceOpen?: boolean
    onCloseDrawer: () => void
};

const arePropsEqual = (prevProps: FilterDrawerProps, newProps: FilterDrawerProps) => {
    return !!prevProps.forceOpen === !!newProps.forceOpen && // soft compare boolean
        utils.DeepEqual(prevProps.options, newProps.options) &&
        utils.DeepEqual(prevProps.initialValues, newProps.initialValues);
};


const FilterDrawer_: FC<FilterDrawerProps> = ({ options, onFilter, initialValues, forceOpen = false, onCloseDrawer }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const afterMatcher = { after: new Date() };
    const transactionType = [];
    if (initialValues.credit) transactionType.push('credit');
    if (initialValues.debit) transactionType.push('debit');
    const computedInitialValues: TransactionFilterFormValues = {
        ...initialValues,
        transactionType,
    };

    const handleSubmit = (formParams: TransactionFilterFormValues, form: FormikHelpers<TransactionFilterFormValues>) => {
        onFilter({
            buckets: formParams.buckets,
            dateRange: formParams.dateRange,
            credit: formParams.transactionType.includes('credit'),
            debit: formParams.transactionType.includes('debit'),
            description: formParams.description,
        });
        onClose();
    };

    function handleClearFilters(this: FormikHelpers<TransactionFilterFormValues>) {
        this.resetForm({
            values: {
                buckets: [],
                description: '',
                transactionType: ['credit', 'debit'],
                dateRange: { from: undefined, to: undefined },
            }
        });
    };

    const handleClose = () => {
        onCloseDrawer();
        onClose();
    }

    return (
        <>
            <Button leftIcon={<Funnel />} colorScheme={UiColors.buttonMajor} onClick={onOpen}>
                Filter Transactions
            </Button>
            <Drawer
                isOpen={isOpen || forceOpen}
                placement='left'
                size='md'
                onClose={handleClose}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'> Filter Transactions</DrawerHeader>

                    <DrawerBody>
                        <Formik initialValues={computedInitialValues} onSubmit={handleSubmit}>
                            {(form) => (
                                <Form><Stack spacing='24px'>
                                    <Box>
                                        <FormLabel htmlFor='dateRange'>Date Range</FormLabel>
                                        <Field name='dateRange'>
                                            {({ field, form }: any) => (
                                                <FormControl isInvalid={form.errors.buckets}>
                                                    <FormLabel htmlFor='dateRange'>Buckets</FormLabel>
                                                    <DatePicker {...field} onChange={(params) => form.setFieldValue(field.name, params)}
                                                        disabled={afterMatcher} />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Box>

                                    <Box>
                                        <FormLabel htmlFor='description'>Description</FormLabel>
                                        <Field name='description' as={Input} id='description' placeholder='Filter description keywords' />
                                    </Box>

                                    <Box>
                                        <Field name='transactionType'>
                                            {({ field, form }: any) => (
                                                <FormControl isInvalid={form.errors.transactionType}>
                                                    <FormLabel htmlFor='transactionType'>Transaction Type</FormLabel>
                                                    <CheckboxGroup {...field} onChange={(params) => form.setFieldValue(field.name, params)} colorScheme='green'  >
                                                        <Stack id='transactionType' spacing={[1, 5]} direction={['column', 'row']}>
                                                            <Checkbox name='credit' type="checkbox" colorScheme='green' value='credit' >Credit</Checkbox>
                                                            <Checkbox name='debit' type="checkbox" colorScheme='red' value='debit'>Debit</Checkbox>
                                                        </Stack>
                                                    </CheckboxGroup>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Box>

                                    <Box>
                                        <Field name='buckets'>
                                            {({ field, form }: any) => (
                                                <FormControl isInvalid={form.errors.buckets}>
                                                    <FormLabel htmlFor='buckets'>Buckets</FormLabel>
                                                    <MultiCategoryPicker  {...field} placeholder='Select Buckets...' onChange={(params) => form.setFieldValue(field.name, params)} options={options} />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Box>
                                    <Stack direction='row' spacing={4} alignSelf={'end'}>
                                        <Button leftIcon={<XLg />} variant='outline' onClick={handleClearFilters.bind(form)} colorScheme={'gray'}>Clear Filters</Button>
                                        <Button rightIcon={<ChevronDoubleRight />} type="submit" colorScheme={UiColors.buttonMinor}>Apply Filters</Button>
                                    </Stack>
                                </Stack></Form>)}
                        </Formik>
                    </DrawerBody>

                </DrawerContent>
            </Drawer >
        </>
    );
}

export const FilterDrawer = React.memo(FilterDrawer_, arePropsEqual);