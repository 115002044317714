const Home: React.FC<{}> = () => {
  return (
    <div className="container">
      <header className="jumbotron">
        <h3>You are Home</h3>
      </header>
    </div>
  );
};

export default Home;
