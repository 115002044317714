import {
  CreateBucketRequestData,

  EditTransactionAmountRequestData,
  EditMultipleTransactionsBucketRequestData,
  EditTransactionBucketRequestData,
  EnrollAccountRequestData, EnrollAccountResponse,
  GetBucketsResponse,
  GetTransactionsResponse,
  ParentBucket, Transaction,
  Account,
  GetAccountsResponse,
  GetSauceResponse,
  CustomerInfo,
  EditBucketRequestData,
  GetBucketsMappingResponse,
  GetBucketStructureResponse,
  TransactionList,
  SplitTransactionRequestData,
  SyncAccountsRequestData,
  EnrollmentRenameRequestData,
  AccountModifyRequestData,
  ModifyAccountOptions,
  RefreshEnrollmentRequestData
} from '../types/server.type';
import { Result, api, handleFailedApiCall } from '../common/api';
import cache from './cache.service';
import { TellerConnectEnrollment } from 'teller-connect-react';
import { TellerParams } from '../types/user.type';

interface BaseCreateBucketParams {
  name: string,
  goal: number,
  parentId?: number,
  transactionId?: string
}

const successResult = {
  success: true,
  message: 'Completed'
};

export interface CreateExpenseBucketParams extends BaseCreateBucketParams { rollover: boolean, isIncome: undefined }
export interface CreateIncomeBucketParams extends BaseCreateBucketParams { isIncome: true, rollover: undefined }

export type CreateBucketParams = CreateExpenseBucketParams | CreateIncomeBucketParams;

export interface EditBucketParams {
  id: number,
  name?: string,
  goal?: number,
  rollover?: boolean
  parentId?: number
}

class UserService {
  async editTransactionAmount(transactionId: string, amount: number): Promise<Result> {
    try {
      await api.post<EditTransactionAmountRequestData>("edit/transaction/amount", {
        transactionId,
        amount,
      });

      return successResult;
    } catch (error) {
      return handleFailedApiCall(error, 'Failed to edit transaction amount');
    }
  }

  async splitTransaction({ splits, transactionId }: SplitTransactionRequestData): Promise<Result> {
    try {
      await api.post<SplitTransactionRequestData>("split/transaction", {
        transactionId,
        splits
      });

      return successResult;
    } catch (error) {
      let errorResult = handleFailedApiCall(error, 'Failed to split transaction.');
      return { ...errorResult };
    }
  }

  async editTransactionBucket(transactionId: string, bucketid: number): Promise<Result> {
    try {
      await api.post<EditTransactionBucketRequestData>("edit/transaction/bucket", {
        transactionId,
        bucketid,
      });

      return successResult;
    } catch (error) {
      return handleFailedApiCall(error, 'Failed to edit transaction bucket');
    }
  }

  async editMultipleTransactionBucket(transactionIds: string[], bucketid: number): Promise<Result> {
    try {
      await api.post<EditMultipleTransactionsBucketRequestData>("edit/transaction/buckets", {
        transactionIds,
        bucketid,
      });

      return successResult;
    } catch (error) {
      return handleFailedApiCall(error, 'Failed to edit the buckets of the transactions');
    }
  }

  async editBucket({ id, name, goal, rollover, parentId }: EditBucketParams): Promise<Result> {
    try {
      await api.post<EditBucketRequestData>("edit/bucket", {
        bucketid: id,
        name,
        goal,
        rollover,
        parentId,
      });

      return successResult;
    } catch (error) {
      return handleFailedApiCall(error, 'Failed to edit bucket');
    }
  }

  async enroll(tellerEnrollment: TellerConnectEnrollment, enviroment: string,
    prevEnrollmentId?: string): Promise<Result<CustomerInfo>> {
    const returnInfo = true;
    let response: EnrollAccountResponse;
    try {
      response = await api.post<EnrollAccountRequestData, EnrollAccountResponse>("enroll", {
        prevEnrollmentId,
        enrollment: {
          accessToken: tellerEnrollment.accessToken,
          enrollmentID: tellerEnrollment.enrollment.id,
          institution: tellerEnrollment.enrollment.institution,
          tellerUserId: tellerEnrollment.user.id,
        },
        signatures: tellerEnrollment.signatures || [],
        environment: enviroment,
        returnInfo,
      });

      cache.cacheUserData(response.data.customerInfo);

      return {
        success: true,
        message: `You have succesfully enrolled your accounts from: ${response.data.institution}`,
        data: response.data.customerInfo,
      };
    } catch (error) {
      let errorResult = handleFailedApiCall<EnrollAccountResponse>(error, 'Failed to connect accounts.');
      return { ...errorResult };
    }
  }

  async refreshEnrollment(enrollmentId: string): Promise<Result> {
    try {
      await api.post<RefreshEnrollmentRequestData>("refresh/enrollment", {
        enrollmentId,
      });

      return successResult;
    } catch (error) {
      let errorResult = handleFailedApiCall(error, 'Failed to refresh enrollment');
      return { ...errorResult };
    }
  }

  async renameEnrollment(params: EnrollmentRenameRequestData): Promise<Result> {
    try {
      await api.post<EnrollmentRenameRequestData>("rename/enrollment", params);

      return successResult;
    } catch (error) {
      let errorResult = handleFailedApiCall(error, 'Failed to refresh account');
      return { ...errorResult };
    }
  }

  async modifyAccount(accountId: string, action: keyof (typeof ModifyAccountOptions), nickname?: string, hide?: boolean): Promise<Result> {
    try {
      await api.post<AccountModifyRequestData>("modify/account", {
        accountId,
        action,
        nickname,
        hide,
      });

      return successResult;
    } catch (error) {
      let errorResult = handleFailedApiCall(error, 'Failed to modify account');
      return { ...errorResult };
    }
  }

  async syncAccounts(): Promise<Result> {
    try {
      await api.post<SyncAccountsRequestData>("sync/accounts", {});
      return successResult;
    } catch (error) {
      let errorResult = handleFailedApiCall(error, 'Failed to refresh accounts');
      return { ...errorResult };
    }
  }

  async getTransactions(): Promise<Result<TransactionList>> {
    try {
      const response = await api.get<GetTransactionsResponse>("transactions");

      cache.cacheUserData({ transactions: response.data.transactions });
      return { success: true, message: ``, data: response.data.transactions };
    } catch (error) {
      let errorResult = handleFailedApiCall<GetTransactionsResponse>(error, 'Failed to retrieve transactions');
      return { ...errorResult };
    }
  }

  async getAccounts(): Promise<Result<Account[]>> {
    try {
      const response = await api.get<GetAccountsResponse>("accounts");

      cache.cacheUserData({ accounts: response.data.accounts });
      return { success: true, message: ``, data: response.data.accounts };
    } catch (error) {
      let errorResult = handleFailedApiCall<GetTransactionsResponse>(error, 'Failed to retrieve accounts');
      return { ...errorResult };
    }
  }

  async getBuckets({ month, year }: { month?: number, year?: number }): Promise<Result<ParentBucket[]>> {
    try {
      if (!month || !year) {
        const today = new Date();
        month = today.getMonth() + 1; // JS month starts from 0
        year = today.getUTCFullYear();
      }
      const response = await api.get<GetBucketsResponse>(`buckets/${month}/${year}`);

      cache.cacheUserData({ buckets: response.data.buckets });
      return { success: true, message: ``, data: response.data.buckets };
    } catch (error) {
      let errorResult = handleFailedApiCall<GetBucketsResponse>(error, 'Failed to retrieve transactions');
      return { ...errorResult };
    }
  }

  async getSauceAndMixer(): Promise<Result<TellerParams>> {
    try {
      const { data } = await api.get<GetSauceResponse>("sauce");

      cache.cacheSauce(data);
      return { success: true, message: ``, data };
    } catch (error) {
      let errorResult = handleFailedApiCall<GetTransactionsResponse>(error, 'Failed to get sauce');
      return { ...errorResult };
    }
  }

  async getBucketMapping(): Promise<Result<{ [key: number]: string }>> {
    try {
      const { data: { bucketToName } } = await api.get<GetBucketsMappingResponse>("mapping/buckets");

      cache.cacheBucketMapping(bucketToName);
      return { success: true, message: ``, data: bucketToName };
    } catch (error) {
      let errorResult = handleFailedApiCall<GetTransactionsResponse>(error, 'Failed to get bucket mapping');
      return { ...errorResult };
    }
  }

  async getBucketStructure(): Promise<Result<{ [key: number]: number[] }>> {
    try {
      const { data: { bucketStructure } } = await api.get<GetBucketStructureResponse>("structure/buckets");

      cache.cacheBucketStructure(bucketStructure);
      return { success: true, message: ``, data: bucketStructure };
    } catch (error) {
      let errorResult = handleFailedApiCall<GetTransactionsResponse>(error, 'Failed to get buckets structure');
      return { ...errorResult };
    }
  }

  async createBucket(params: CreateBucketParams): Promise<Result> {
    const { name, goal, parentId, transactionId, isIncome, rollover } = params;
    try {
      await api.post<CreateBucketRequestData>("create/bucket", {
        transactionId,
        bucketName: name,
        parentId,
        goal,
        rollover: !!rollover,
        isIncome: !!isIncome,
      });

      return successResult;
    } catch (error) {
      let errorResult = handleFailedApiCall(error, 'Failed to create bucket.');
      return { ...errorResult };
    }
  }

  // async createBuckets(transactionId: string, bucketName: string, parentId: string | undefined): Promise<Result<{ [key: string]: Transaction }>> {
  //   try {
  //     const response = await api.post<CreateBucketRequestData, CreateBucketResponse>("/create/buckets", {
  //       transactionId,
  //       bucketName,
  //       parentId,
  //     });


  //     return { success: true, message: 'Bucket was created successfully!' };
  //   } catch (error) {
  //     let errorResult = handleFailedApiCall<EnrollAccountResponse>(error, 'Failed to create bucket.');
  //     return { ...errorResult };
  //   }
  // }

  async getTransactionsStub() {
    // return axios.post(API_URL + "enroll", {
    //   username: localStorage.getItem("username"),
    //   accessToken: 'test_token_mzivfhorcjyvw',
    // }).then(response => {
    //   if (response.data.status === 'success') {
    //     return { success: true, data: response.data };
    //   }

    //   return { success: false, errorMessage: 'Failed to get transactions', data: null }; // todo: use rejected promise, is this even needed since axios will handle non-200
    // });
    // return {
    //   success: false,
    //   errorMessage: 'Did not succeed lol',
    // }
    return {
      success: true,
      data: {
        transactions: <Transaction[]>[
          {
            id: '1',
            description: 'Rooster & Rice',
            date: '2024-02-29T00:00:00Z',
            amount: -90.26,
            bucketid: 1,
            bucketname: 'restaurants',
          },
          {
            id: '2',
            description: 'AT&T Company Contract',
            date: '2024-02-18T00:00:00Z',
            amount: -51.26,
            bucketid: 3,
            bucketname: 'bills',
          },
          {
            id: '3',
            description: 'Monthly Water Bill',
            date: '2024-03-12T00:00:00Z',
            amount: -67.26,
            bucketid: 3,
            bucketname: 'bills',
          },
          {
            id: '4',
            description: 'Monthtly Rental',
            date: '2024-03-07T00:00:00Z',
            amount: 90.26,
            bucketid: 4,
            bucketname: 'rental income',
          },
        ]
      }
    }
  }

}

const userService = new UserService();
export default userService;
