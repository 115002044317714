import React, { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { signIn, signUp } from "supertokens-web-js/recipe/emailpassword";
import { signOut } from "supertokens-auth-react/recipe/session";

import { logger } from "../common/utils";
import { Result } from "../common/api";
import routenames from "../common/routenames";
import cache from "./cache.service";
import { Props } from "../types/react.type";
import { LoginFormValues, RegisterFormValues } from "../types/user.type";
import { UserInfo } from "../types/server.type";

const getJSONResult = async (raw: Response): Promise<{ [key: string]: any }> => {
  try {
    return await raw.json();
  } catch (err: any) {
    logger.error(err);
    return {};
  }
};

function logout() {
  signOut();
  cache.clear();
}

function completeSignIn(userInfo: UserInfo) {
  cache.cacheUserInfo(userInfo);
}

async function login({ email, password }: LoginFormValues): Promise<Result<Partial<LoginFormValues>>> {
  const result: Result<Partial<LoginFormValues>> = { success: false, message: '' };

  try {
    const response = await signIn({
      formFields: [
        { id: "email", value: email },
        { id: "password", value: password },
      ],
    });

    if (response.status === "FIELD_ERROR") {
      const formErrors: Partial<LoginFormValues> = {};
      response.formFields.forEach(formField => {
        if (formField.id === "email") {
          // Email validation failed (for example incorrect email syntax).
          formErrors.email = formField.error;
        }
      });

      result.message = 'The email entered is invalid';
    } else if (response.status === "WRONG_CREDENTIALS_ERROR") {
      result.message = 'Email password combination is incorrect';
    } else if (response.status === "SIGN_IN_NOT_ALLOWED") {
      // the reason string is a user friendly message
      // about what went wrong. It can also contain a support code which users
      // can tell you so you know why their sign in was not allowed.
      result.message = response.reason;
    } else {
      // sign in successful.
      result.success = true;
      result.message = 'Login Successful!';

      let rawResponse = await response.fetchResponse.json();
      if (!rawResponse) {
        logger.error(`No user info was received. Using default values`);
        rawResponse = {};
        rawResponse.data = { firstName: `Customer`, lastName: `` };
      }

      completeSignIn(rawResponse.data);
    }
  } catch (error: any) {
    logger.log(error);
    if (error instanceof Response) {
      error = await getJSONResult(error);
    }

    if (error.isSuperTokensGeneralError === true) {
      result.message = error.message;
    } else {
      result.message = error.data?.errorMessage || 'Failed to login';
    }
  }

  return result;
}

async function register({ firstName, lastName, password, email }: RegisterFormValues): Promise<Result<Partial<RegisterFormValues>>> {
  const result: Result<Partial<RegisterFormValues>> = { success: false, message: '' };
  try {
    const response = await signUp({
      formFields: [
        { id: "email", value: email },
        { id: "password", value: password },
        { id: "firstName", value: firstName },
        { id: "lastName", value: lastName },
      ],
    });

    if (response.status === "FIELD_ERROR") {
      // one of the input formFields failed validaiton
      const formErrors: Partial<RegisterFormValues> = {};
      response.formFields.forEach(formField => {
        if (formField.id === "email") {
          // Email validation failed (for example incorrect email syntax),
          // or the email is not unique.
          formErrors.email = formField.error;
        } else if (formField.id === "password") {
          // Password validation failed.
          formErrors.password = formField.error;
        }
        else if (formField.id === "firstName") {
          formErrors.firstName = formField.error;
        }
        else if (formField.id === "lastName") {
          formErrors.lastName = formField.error;
        }
      });

      result.message = 'There is an error in at least one of the fields';
      result.data = formErrors;
    } else if (response.status === "SIGN_UP_NOT_ALLOWED") {
      // the reason string is a user friendly message
      // about what went wrong. It can also contain a support code which users
      // can tell you so you know why their sign up was not allowed.
      result.message = response.reason;
    } else {
      // sign up successful. The session tokens are automatically handled by
      // the frontend SDK.
      result.success = true;
      result.message = 'Registration Successful!';

      cache.cacheRegistration(email);
    }
  } catch (error: any) {
    logger.log(error);
    if (error instanceof Response) {
      error = await getJSONResult(error);
    }

    if (error.isSuperTokensGeneralError === true) {
      result.message = error.message;
    } else {
      result.message = error.data?.errorMessage || error.message || 'Unfortunately failed to register. Please try again later.';
    }
  }

  return result;
}

const authService = {
  login,
  logout,
  register,
};

export function useLoginStatus() {
  return { authenticate: authService.login, register: authService.register, user: cache.getCurrentUser() };
}

export function useLogout() {
  const navigate = useNavigate();
  return (path = routenames.login) => {
    logout();
    navigate(path);
  }
};

export type IAuthenticatedCtx = {
  isLoggedIn: true,
  logout: () => void
  user: UserInfo
};

export type IUnauthenticatedCtx = {
  isLoggedIn: false
  login: (loginParams: LoginFormValues, redirectPath?: string) => ReturnType<typeof login>
  register: typeof register,
};

type IAuthContext = IAuthenticatedCtx | IUnauthenticatedCtx;
const AuthContext = createContext<IAuthContext | null>(null);

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const { authenticate, user, register } = useLoginStatus();
  const logout = useLogout();

  const login = async ({ email, password }: LoginFormValues, postLoginRoute?: string) => {
    const loginResult = await authenticate({ email, password });
    if (loginResult.success) {

      navigate(postLoginRoute ?? routenames.app, { replace: true });
      return loginResult;
    }

    return loginResult;
  };

  const isLoggedIn = (user !== null);
  const authState: IAuthContext = isLoggedIn ?
    { isLoggedIn, logout, user }
    :
    { isLoggedIn, login, register };
  const value = useMemo(() => authState, [isLoggedIn]);

  // <AuthContext.Provider value={ value }> { children } < /AuthContext.Provider>;
  return React.createElement(
    AuthContext.Provider,
    { value },
    children,
  );
};

export const useAuth = (): IAuthContext => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  if (authCtx === null) {
    // This clause should be impossible to reach because by the time the React tree is created.
    // there will already be a valid auth context. This clause is mostly for typing
    logger.error('Failure to create authentication context');
    navigate(routenames.app);
    return {} as IAuthContext; // This will never get called
  }

  return authCtx as IAuthContext;
};

export default authService;
