import { Formik, Field, Form, ErrorMessage, FormikHelpers } from "formik";
import { Link as ReactRouterLink } from 'react-router-dom'
import { Box, Button, Link as ChakraLink, Checkbox, Divider, FormControl, FormLabel, HStack, Heading, Icon, LinkProps, Stack, Text } from '@chakra-ui/react'
import * as Yup from "yup";

import { IUnauthenticatedCtx, useAuth } from "../services/auth.service";
import { logger } from "../common/utils";
import { RegisterFormValues } from "../types/user.type";
import { useState } from "react";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Link } from "@chakra-ui/react";
import { BoxArrowUpRight, CheckCircleFill } from "react-bootstrap-icons";
import routenames from "../common/routenames";
import { Input, RegisterPasswordField, OAuthButtonGroup, FormHeader } from "../components/FormElements";

const initialValues: RegisterFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};

const Register: React.FC<{}> = () => {
  const [completedRegistration, setCompletedRegistration] = useState<boolean>(false);
  const auth = useAuth() as IUnauthenticatedCtx;
  if (auth.isLoggedIn) {
    logger.error(`Invalid route state isLoggedIn: ${auth.isLoggedIn}`)
  }

  function validationSchema() {
    const yupValidateName = (label: string) => Yup.string()
      .test(
        "len",
        `The ${label} must be between 3 and 20 characters.`,
        (val: any) =>
          val &&
          val.toString().length >= 3 &&
          val.toString().length <= 20
      )
      .required("This field is required!");
    return Yup.object().shape({
      firstName: yupValidateName('first name'),
      lastName: yupValidateName('last name'),
      email: Yup.string()
        .email("This is not a valid email.")
        .required("This field is required!"),
      password: Yup.string()
        .test(
          "len",
          "The password must be between 6 and 40 characters.",
          (val: any) =>
            val &&
            val.toString().length >= 6 &&
            val.toString().length <= 40
        )
        .required("This field is required!"),
    });
  }

  async function handleRegister(formValues: RegisterFormValues, form: FormikHelpers<RegisterFormValues>) {
    console.log(formValues);
    if (auth.isLoggedIn || !auth.register) {
      logger.error(`Handling regiter in auth state. isLoggedIn: ${auth.isLoggedIn}`)
      return;
    }
    const registerResult = await auth.register(formValues);
    if (registerResult.success) {
      setCompletedRegistration(true);
      form.setSubmitting(false);
      form.setStatus();
      return;
    }

    form.setStatus(registerResult.message);
    form.setErrors(registerResult.data || {});
    form.setSubmitting(false);
  }

  return (
    <>
      <FormHeader>
        <Heading size={{ base: 'xs', md: 'sm' }}>Create an account</Heading>
        <Text color="fg.muted">
          Already have an account? <ChakraLink as={ReactRouterLink} to={routenames.login}>Sign in</ChakraLink>
        </Text>
      </FormHeader>
      {completedRegistration && (
        <Alert
          status='success'
          variant='subtle'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          textAlign='center'
          height='200px'
        >
          <AlertIcon as={CheckCircleFill} boxSize='40px' mr={0} />
          <AlertTitle mt={4} mb={1} fontSize='lg'>
            Registration Successful!
          </AlertTitle>
          <AlertDescription maxWidth='sm'>
            Thanks for creating an account. <ChakraLink as={ReactRouterLink} to={routenames.login} isExternal>Login <Icon as={BoxArrowUpRight} size={'10px'} /></ChakraLink> and start budgeting right!
          </AlertDescription>
        </Alert>
      )}
      <Box
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg={{ base: 'transparent', sm: 'bg.surface' }}
        boxShadow={{ base: 'none', sm: 'md' }}
        borderRadius={{ base: 'none', sm: 'xl' }}
      >
        <Stack spacing="6">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleRegister}
          >
            {({ isSubmitting, status }) => (
              <Form>
                <Stack spacing="5">
                  <Field name='firstName'>
                    {({ field, form }: any) => <Input label='First Name' field={field} form={form} />}
                  </Field>
                  <Field name='lastName'>
                    {({ field, form }: any) => <Input label='Last Name' field={field} form={form} />}
                  </Field>
                  <Field name='email'>
                    {({ field, form }: any) => <Input label='Email' field={field} form={form} type='email' />}
                  </Field>
                  <Field name='password'>
                    {({ field, form }: any) => <RegisterPasswordField form={form} field={field} />}
                  </Field>
                </Stack>
                <Stack spacing="6">
                  <Button loadingText='Registering..' isLoading={isSubmitting} type="submit">Register</Button>
                  {status &&
                    <Alert status='error'>
                      <AlertIcon />{status}
                    </Alert>
                  }
                  <HStack>
                    <Divider />
                    <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted"> or continue with</Text>
                    <Divider />
                  </HStack>
                  <OAuthButtonGroup />
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Box>
    </>
  );
}

export default Register;
