import { Route, createBrowserRouter, createRoutesFromElements, Navigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";


import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Transactions from "./pages/Transactions";

import { RequireAuth } from "./components/RequireAuth";
import Budget from "./pages/Budget";
import routenames from "./common/routenames";
import { AuthLayout } from "./components/AuthLayout";
import { HomeLayout } from "./components/HomeLayout";
import { AppLayout } from "./components/AppLayout";
import { InitializeSuperTokens } from "./services/supertokens.service";

const getRelativePath = (path: string, paramters?: string) => {
  const relativePath = path.substring(path.lastIndexOf("/") + 1);
  if (paramters) {
    return `${relativePath}/${paramters}`;
  }
  return relativePath;
};

InitializeSuperTokens();

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthLayout />} >
      <Route element={<HomeLayout />} >
        <Route path={routenames.home} element={<Home />} />
        <Route path={routenames.login} element={<Login />} />
        <Route path={routenames.register} element={<Register />} />
      </Route>

      <Route element={<RequireAuth />}>
        <Route path={routenames.app} element={<AppLayout />}>
          <Route path={getRelativePath(routenames.budget)} element={<Budget />} />
          <Route element={<Profile />} index />
          <Route path={getRelativePath(routenames.transactions)} element={<Transactions />} >
            <Route path=':filter' element={<Transactions />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<Navigate to={routenames.home} />} />
    </Route>
  )
);
