import {
    Box,
    Flex,
    Button,
    Stack,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { UiColors } from '../common/constants';
import routenames from '../common/routenames';
import { Logo } from './icons';

export const Navbar = () => {
    return (
        <Flex as="nav" role="navigation" bg="bg.accent.default" justifyContent={'space-between'} h={'60px'} boxShadow='0px 1px 5px lightgray' borderBottomWidth='thin'>
            <Box ml={'30px'} justifySelf='center' alignSelf='center' as='b'><Logo height='8' /></Box>
            <Flex direction={'column'} justifyContent='center' >
                <Stack direction='row' spacing={6} alignSelf={'end'} justifySelf={'center'} mr='30px'>
                    <Button as={ReactRouterLink} variant='link' colorScheme={'gray'} to={routenames.login}>Sign In</Button>
                    <Button as={ReactRouterLink} variant='link' colorScheme={UiColors.buttonMajor} to={routenames.register}>Register</Button>
                </Stack>
            </Flex>
        </Flex>
    );
}