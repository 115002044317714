import { FC, useState } from "react";
import {
    Button, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    ThemeTypings
} from "@chakra-ui/react";

interface confirmationHandler {
    name: string;
    handler: (context?: any) => void;
}

interface modalStyling {
    confirmationButtonColorScheme?: ThemeTypings["colorSchemes"]
    rejectionButtonColorScheme?: ThemeTypings["colorSchemes"]
}

interface PartialConfirmationPopupConfig {
    styling?: modalStyling;
    title: React.ReactNode;
    body?: React.ReactNode;
    confirm: confirmationHandler | null;
}

interface ConfirmationPopupConfig extends PartialConfirmationPopupConfig {
    reject?: {
        name: string | null;
        handler?: (context?: any) => void;
    };
}

interface ModalContainerProps extends PartialConfirmationPopupConfig {
    isOpen: boolean;
    reject: {
        name: string | null;
        handler: (context?: any) => void;
    };
};

const ModalContainer: FC<ModalContainerProps> = (props) => {
    const { isOpen, title, confirm, reject, body, styling } = props;

    const acceptColorScheme = styling?.confirmationButtonColorScheme ?? 'teal';
    const rejectionColorScheme = styling?.rejectionButtonColorScheme;

    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={reject.handler}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    {body && body}
                </ModalBody>

                <ModalFooter>
                    {confirm && <Button onClick={confirm.handler} colorScheme={acceptColorScheme} mr={3}>
                        {confirm.name}
                    </Button>}
                    {reject.name && <Button {...{ colorScheme: rejectionColorScheme }} onClick={reject.handler} mr={3}>
                        {reject.name}
                    </Button>}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export const useModalConfirmation = (): [((config: ConfirmationPopupConfig) => void), JSX.Element] => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [[title, body], setContent] = useState<[React.ReactNode, React.ReactNode]>([null, null]);
    const [styling, setStyling] = useState<modalStyling | undefined>();

    const [confirmText, setConfirmText] = useState<string | null>(null);
    const [rejectText, setRejectText] = useState<string | null>(null);
    const [accepter, setAccepter] = useState(() => () => onClose());
    const [rejecter, setRejecter] = useState(() => () => onClose());

    const popConfirmationModal = (config: ConfirmationPopupConfig) => {
        // Set content and styling
        setContent([config.title, config.body]);
        setStyling(config.styling);

        // Setup handlers
        const onModalCloseClose = () => {
            config.reject && config.reject.handler && config.reject.handler();
            onClose();
        };
        setRejecter(() => onModalCloseClose);
        setRejectText(config.reject?.name ?? null);

        if (config.confirm) {
            const onModalConfirm = () => {
                config.confirm && config.confirm.handler();
                onClose();
            };
            setAccepter(() => onModalConfirm);
            setConfirmText(config.confirm.name)
        } else {
            setConfirmText(null);
        }

        // open modal
        onOpen();
    };

    const ConfirmationModal = <ModalContainer title={title} isOpen={isOpen} body={body}
        styling={styling}
        reject={{ name: rejectText, handler: rejecter }}
        confirm={confirmText ? { name: confirmText, handler: accepter } : null}
    />;

    return [
        popConfirmationModal,
        ConfirmationModal,
    ];
};
