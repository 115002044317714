import { UseToastOptions } from "@chakra-ui/react";

const Constants = {
    UserDefinedBase: 10000,
    UncategorizedBucketId: 0,
};

export const UiColors = {
    main: 'teal',
    buttonMajor: 'teal',
    buttonMinor: 'blue',
    error: 'crimson',
};

export const errorToast: Readonly<UseToastOptions> = {
    position: 'top-right',
    isClosable: true,
    duration: 9000,
    status: 'error',
    containerStyle: { opacity: .85 },
};

export const successToast: Readonly<UseToastOptions> = {
    position: 'top-right',
    isClosable: true,
    duration: 9000,
    status: 'success',
    containerStyle: { opacity: .85 },
};

export default Constants;