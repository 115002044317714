import React, { FC, forwardRef } from "react";
import {
    Alert,
    AlertIcon,
    Button, Checkbox, FormControl, FormErrorMessage, FormLabel, Icon, Input,
    InputGroup, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton,
    ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, useDisclosure
} from "@chakra-ui/react";
import { Field, FieldInputProps, Form, Formik, FormikHelpers, FormikState } from "formik";
import { CheckCircleFill, Pencil, PlusCircleFill, XCircleFill } from "react-bootstrap-icons";
import { CategoryPicker, CategoryPickerOption } from "./CategoryPicker.component";
import { DecoratedInput, RefDecoratedInput } from "./FormElements";
import { Optional } from "../types/type-utilities";
import { CreateBucketParams } from "../services/user.service";

export interface EditBucketValues extends Optional<CreateBucketParams, 'parentId'> { }

interface CreateBucketModalProps {
    buttonName: string
    loading?: boolean,
    errorMessage: string | null,
    bucketList: readonly CategoryPickerOption[] | null
    initialValues: CreateBucketParams
    onSaved: (input: CreateBucketParams) => void,
    onModalClosed: () => void,
    onModalOpen?: () => void, // TODO(UNUSED)
    forceOpen?: boolean
}
export const CreateBucketModal: FC<CreateBucketModalProps> =
    ({ buttonName, loading, initialValues, onSaved, errorMessage, onModalClosed, onModalOpen, bucketList = null, forceOpen = false }) => {
        const { isOpen, onOpen, onClose } = useDisclosure();
        const nameInputRef = React.useRef<HTMLInputElement>(null);

        const handleSaved = (values: CreateBucketParams) => {
            // todo: wait till field values have been set before sending since we use setfieldvalue
            onSaved(values);
            onClose();
        };

        const handleClosed = () => {
            onModalClosed();
            onClose();
        };

        const handleOpen = () => {
            if (onModalOpen) {
                onModalOpen();
            }
            onOpen();
        };

        return (
            <>
                <Button onClick={handleOpen} leftIcon={<PlusCircleFill />} colorScheme='teal' variant='solid'>{buttonName}</Button>
                <Modal
                    isCentered
                    onClose={handleClosed}
                    isOpen={isOpen || errorMessage !== null || forceOpen}
                    motionPreset='slideInBottom'
                    initialFocusRef={nameInputRef}>
                    <ModalOverlay />
                    <Formik initialValues={initialValues} onSubmit={handleSaved}>
                        <Form>
                            <ModalContent>
                                <ModalHeader>Create New Bucket</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody pb={6}>
                                    <Field name='name'>
                                        {({ field, form }: any) => (
                                            <FormControl isInvalid={form.errors.name}>
                                                <FormLabel>Bucket name:</FormLabel>
                                                <InputGroup >
                                                    <Input errorBorderColor='crimson' {...field} placeholder='Enter a name for the new bucket' />
                                                    <InputRightElement><Icon size='1rem' as={form?.error?.name ? XCircleFill : CheckCircleFill} color='green.500' /></InputRightElement>
                                                </InputGroup>
                                                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Field name='goal'>
                                        {({ field, form }: any) => (
                                            <FormControl isInvalid={form.errors.goal}>
                                                <FormLabel>Goal</FormLabel>
                                                <InputGroup >
                                                    <InputLeftElement pointerEvents='none' color='gray.300' fontSize='1.2em'>$</InputLeftElement>
                                                    <Input type="number" errorBorderColor='crimson' {...field} placeholder='Enter goal' />
                                                    <InputRightElement><Icon size='1rem' as={form?.error?.name ? XCircleFill : CheckCircleFill} color='green.500' /></InputRightElement>
                                                </InputGroup>

                                                <FormErrorMessage>{form.errors.goal}</FormErrorMessage>
                                            </FormControl>
                                        )
                                        }
                                    </Field>

                                    <Field name='parentId'>
                                        {({ field, form }: any) => (
                                            <FormControl isInvalid={form.errors.parentBucket}>
                                                <FormLabel>Parent Bucket</FormLabel>
                                                <CategoryPicker options={bucketList} {...field} hasError={!!(form.errors.parentBucket)}
                                                    nonSelectedText="No parent"
                                                    onSelectedBucketChange={(newBucket: CategoryPickerOption) => form.setFieldValue(field.name, newBucket.value)} />
                                                <FormErrorMessage>{form.errors.parentBucket}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    {!initialValues.isIncome && <Field name='rollover'>
                                        {({ field }: any) => <FormControl><Checkbox {...field}>Rollover</Checkbox></FormControl>}
                                    </Field>}
                                    {errorMessage && <Alert status='error'> <AlertIcon />{errorMessage}</Alert>}

                                </ModalBody>
                                <ModalFooter>
                                    {loading ? <Spinner marginRight={'40px'} /> : <Button type="submit" colorScheme='teal' variant='solid' mr={4} >Create</Button>}
                                    <Button onClick={handleClosed}>Cancel</Button>
                                </ModalFooter>
                            </ModalContent >
                        </Form>
                    </Formik>
                </Modal >
            </>
        )
    }

interface EditBucketModalProps {
    // edit bucket properties
    initialValues: EditBucketValues
    bucketList: readonly CategoryPickerOption[] | null

    // UI status properties
    submitLoading?: boolean,
    errorMessage?: string | null,

    // handlers
    onSaved: (input: EditBucketValues) => void,
    onModalOpen?: () => void, // TODO(UNUSED)
    onModalClosed: () => void,
}
export const EditBucketModal: FC<EditBucketModalProps> =
    ({ submitLoading, initialValues, onSaved, errorMessage = null, onModalClosed, onModalOpen, bucketList }) => {
        const { isOpen, onOpen, onClose } = useDisclosure();
        const nameInputRef = React.useRef<HTMLInputElement>(null);
        const DecoratedInputWithRef = forwardRef(RefDecoratedInput);
        const handleSaved = (values: EditBucketValues) => {
            // todo: wait till field values have been set before sending since we use setfieldvalue
            onSaved(values);
            onClose();
        };

        const handleClosed = () => {
            onModalClosed();
            onClose();
        };

        const handleOpen = () => {
            if (onModalOpen) {
                onModalOpen();
            }
            onOpen();
        };

        return (
            <>
                <Icon as={Pencil} _hover={{ color: 'white' }} onClick={handleOpen} />
                <Modal
                    isCentered
                    onClose={handleClosed}
                    isOpen={isOpen || errorMessage !== null}
                    motionPreset='slideInBottom'
                    initialFocusRef={nameInputRef}>
                    <ModalOverlay />

                    <Formik initialValues={initialValues} onSubmit={handleSaved}>
                        <Form>
                            <ModalContent>
                                <ModalHeader>Edit Bucket</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody pb={6}>
                                    <Field name='name'>
                                        {({ field, form }: any) => (<DecoratedInputWithRef
                                            label='Bucket name:'
                                            field={field}
                                            form={form}
                                            errorBorderColor='crimson'
                                            ref={nameInputRef}
                                            rightComponent={<InputRightElement><Icon size='1rem' as={form?.error?.name ? XCircleFill : CheckCircleFill} color='green.500' /></InputRightElement>}
                                        />)}
                                    </Field>

                                    <Field name='goal'>
                                        {({ field, form }: any) => (
                                            <DecoratedInput
                                                label='Goal:'
                                                field={field}
                                                form={form}
                                                type='number'
                                                leftComponent={<InputLeftElement pointerEvents='none' color='gray.300' fontSize='1.2em'>$</InputLeftElement>}
                                                rightComponent={<InputRightElement><Icon size='1rem' as={form?.error?.name ? XCircleFill : CheckCircleFill} color='green.500' /></InputRightElement>}
                                            />
                                        )
                                        }
                                    </Field>

                                    {<Field name='parentId'>
                                        {({ field, form }: { form: FormikHelpers<EditBucketValues> & FormikState<EditBucketValues>, field: FieldInputProps<EditBucketValues['parentId']> }) => (
                                            <FormControl isInvalid={!!form.errors.parentId}>
                                                <FormLabel>Parent Bucket</FormLabel>
                                                <CategoryPicker options={bucketList} {...field} hasError={!!(form.errors.parentId)}
                                                    nonSelectedText="No parent"
                                                    disableDefaultSelected={initialValues.parentId !== undefined}
                                                    onSelectedBucketChange={(newBucket: CategoryPickerOption) => form.setFieldValue(field.name, newBucket.value)} />
                                                <FormErrorMessage>{form.errors.parentId}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>}

                                    {!initialValues.isIncome && <Field name='rollover'>
                                        {({ field }: any) => <FormControl><Checkbox isChecked={field.value} {...field}>Rollover</Checkbox></FormControl>}
                                    </Field>}
                                    {errorMessage && <Alert status='error'> <AlertIcon />{errorMessage}</Alert>}
                                </ModalBody>
                                <ModalFooter>
                                    {submitLoading ? <Spinner marginRight={'40px'} /> : <Button type="submit" colorScheme='teal' variant='solid' mr={4} >Edit</Button>}
                                    <Button onClick={handleClosed}>Cancel</Button>
                                </ModalFooter>
                            </ModalContent >
                        </Form>
                    </Formik>
                </Modal >
            </>
        )
    }

