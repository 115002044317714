import { Link as ReactRouterLink, Navigate, useLocation } from "react-router-dom";
import { Box, Button, FormControl, FormLabel, Link as ChakraLink, Input as ChakraInput, Stack, Checkbox, Divider, HStack, Text, Alert, AlertIcon, Heading } from "@chakra-ui/react";
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";

import { useAuth } from "../services/auth.service";
import routenames from "../common/routenames";
import { logger } from "../common/utils";
import { LoginFormValues } from "../types/user.type";
import { FormHeader, Input, LoginPasswordField, OAuthButtonGroup, PasswordFieldRefComp } from "../components/FormElements";

export const PasswordField = PasswordFieldRefComp<LoginFormValues>;

const Login: React.FC<{}> = () => {
  const auth = useAuth();
  const location = useLocation();

  if (auth.isLoggedIn) {
    logger.error('Error in route separation');
    // Should be impossible to reach
    return <Navigate to={routenames.app} />;
  }

  function validationSchema() {
    return Yup.object().shape({
      email: Yup.string().required("This field is required!"),
      password: Yup.string().required("This field is required!"),
    });
  }

  async function handleLogin(formValues: LoginFormValues, form: FormikHelpers<LoginFormValues>) {
    const { email, password } = formValues;

    if (auth.isLoggedIn || !auth.login) {
      logger.error(`Handling login in non-login state. isLoggedIn: ${auth.isLoggedIn}`)
      return;
    }
    const loginResult = await auth.login({ email, password }, location?.state?.redirectTo);
    if (!loginResult.success) {
      form.setStatus(loginResult.message);
      form.setErrors(loginResult.data || {});
    }

    form.setSubmitting(false);
  }

  const initialValues: LoginFormValues = {
    email: "",
    password: "",
  };

  return (
    <>
      <FormHeader>
        <Heading size={{ base: 'xs', md: 'sm' }}>Log in to your account</Heading>
        <Text color="fg.muted">
          Don't have an account? <ChakraLink as={ReactRouterLink} to={routenames.register}>Register</ChakraLink>
        </Text>
      </FormHeader>
      <Box
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg={{ base: 'transparent', sm: 'bg.surface' }}
        boxShadow={{ base: 'none', sm: 'md' }}
        borderRadius={{ base: 'none', sm: 'xl' }}
      >
        <Stack spacing="6">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            {({ isSubmitting, status }) => (
              <Form>
                <Stack spacing="5">
                  <Field name='email'>
                    {({ field, form }: any) => <Input label='Email' field={field} form={form} type='email' />}
                  </Field>
                  <Field name='password'>
                    {({ field, form }: any) => <LoginPasswordField form={form} field={field} />}
                  </Field>
                </Stack>
                <HStack justify="space-between">
                  <Checkbox defaultChecked>Remember me</Checkbox>
                  <Button variant="text" size="sm">
                    Forgot password?
                  </Button>
                </HStack>
                <Stack spacing="6">
                  <Button loadingText='Signing In' isLoading={isSubmitting} type="submit">Sign in</Button>
                  {status &&
                    <Alert status='error'>
                      <AlertIcon />{status}
                    </Alert>
                  }
                  <HStack>
                    <Divider />
                    <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted"> or continue with </Text>
                    <Divider />
                  </HStack>
                  <OAuthButtonGroup />
                </Stack>

              </Form>
            )}
          </Formik>
        </Stack>
      </Box>
    </>
  );
}

export default Login;
