import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react'
import { RouterProvider } from "react-router-dom";
import { SuperTokensWrapper } from "supertokens-auth-react";
import theme from './theme'

import './index.css'; // TODO(MOVE): remove -> move to app or applayout
import { router } from './App';
import reportWebVitals from './reportWebVitals';
import { customIcons } from './components/icons';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const customTheme = {
  ...theme,
  icons: {
    ...theme.icons,
    ...customIcons,
  }
}

root.render(
  <StrictMode>
    <ChakraProvider theme={customTheme}>
      <SuperTokensWrapper>
        <RouterProvider router={router} />
      </SuperTokensWrapper>
    </ChakraProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
